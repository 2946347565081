import { useEffect, useState } from "react";
import { getCards } from "../firebase/db";

export const SelectCard = ({ pickCount = 4, back, handleSubmit }) => {
  const [cards, setCards] = useState([]);
  const [selectedCards, setSelectedCards] = useState([]);

  // useEffect
  useEffect(() => {
    const getCardsData = async () => {
      const [cards, error] = await getCards();
      if (!error) {
        setCards(cards);
      }
    };
    getCardsData();
  }, []);

  const selectCard = (e, card) => {
    if (
      pickCount <= selectedCards.length ||
      selectedCards.find((c) => c.url === card.url)
    ) {
      return;
    }

    e.currentTarget.classList.add("selected");
    setSelectedCards([...selectedCards, card]);
  };

  return (
    <div className="h-[100vh] flex flex-col items-center bg-[#FAFAFA] overflow-hidden">
      <div className="w-full h-[3.75rem] bg-white flex items-center justify-center border-b-[1px] border-solid border-[rgba(0,0,0,0.07)] px-8">
        <div className="w-full relative max-w-md py-4 text-center text-lg font-semibold">
          <div className="close left-4 cursor-pointer" onClick={back}></div>
          Kart Seç ({pickCount - selectedCards.length})
        </div>
      </div>
      <div className=" h-[calc(100%-8.5rem)] overflow-auto  flex flex-col items-center max-w-md w-full bg-[#FAFAFA] tracking-[-0.02em] px-4">
        <div className="w-full grid grid-cols-3 pt-2 pb-2">
          {cards.map((card) => (
            <div
              className="flip-card bg-transparent w-[88px] h-[145px] mb-2 justify-self-center cursor-pointer"
              onClick={(e) => selectCard(e, card)}
              key={card.url}
            >
              <div className="flip-card-inner relative w-full h-full text-center">
                <div className="flip-card-front absolute w-full h-full text-black">
                  <img src={card.back} alt="" />
                </div>
                <div className="flip-card-back absolute w-full h-full text-white">
                  <img src={card.url} alt="" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full bg-[#FAFAFA] px-4 py-4 fixed bottom-0 flex justify-center">
        <button
          className={
            "w-full max-w-md disabled:bg-[#9E9E9E] bg-orange-900 justify-self-end py-3 text-white rounded-lg border-none outline-none font-semibold text-md leading-5 cursor-pointer"
          }
          disabled={selectedCards.length !== pickCount}
          onClick={() => handleSubmit(selectedCards)}
        >
          Gönder
        </button>
      </div>
      <style jsx="true">{`
        .close {
          position: absolute;
          width: 32px;
          height: 32px;
          cursor: pointer;
        }
        .close:before,
        .close:after {
          position: absolute;
          top: 4px;
          content: " ";
          height: 20px;
          width: 2px;
          background-color: black;
        }
        .close:before {
          transform: rotate(45deg);
        }
        .close:after {
          transform: rotate(-45deg);
        }
        .flip-card {
          perspective: 1000px;
        }
        .flip-card-inner {
          transition: transform 0.6s;
          transform-style: preserve-3d;
        }
        .flip-card.selected .flip-card-inner {
          transform: rotateX(180deg);
        }
        .flip-card-front,
        .flip-card-back {
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
        }
        .flip-card-back {
          transform: rotateX(180deg);
        }
      `}</style>
    </div>
  );
};

export default SelectCard;
