import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { auth } from ".";
import { setUserData } from "./db";

export const signInWithEmail = async (email, password) => {
  try {
    const credentials = await signInWithEmailAndPassword(auth, email, password);
    return [credentials, null];
  } catch (error) {
    return [null, error];
  }
};

export const signInWithGoogle = async () => {
  try {
    const provider = new GoogleAuthProvider();
    const credentials = await signInWithPopup(auth, provider);
    return [credentials, null];
  } catch (error) {
    return [null, error];
  }
};

export const forgotPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (e) {
    return [null, e];
  }
  return [true, null];
};

export const logout = async () => {
  try {
    await signOut(auth);
    return [true, null];
  } catch (error) {
    return [null, error];
  }
};

export const createUserEmailPassword = async (
  email,
  password,
  name,
  gender
) => {
  try {
    const credential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );

    const user = credential.user;

    if (user != null) {
      return await setUserData(user.uid, name, email, gender);
    } else {
      return [null, true];
    }
  } catch (e) {
    return [null, e];
  }
};
