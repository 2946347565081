import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../components/AuthProvider";
import { sendDreamRequest, getUserData } from "../firebase/db";
import { useAlert } from "react-alert";

export const DreamInterpretation = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [name, setName] = useState(currentUser.name);
  const [dream, setDream] = useState("");
  const alert = useAlert();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (dream === "" || name === "") {
      alert.error("Lütfen tüm alanları doldurunuz.", {
        timeout: 2000,
      });
    } else {
      const [, error] = await sendDreamRequest(currentUser.uid, dream);
      const [user] = await getUserData(currentUser.uid);
      if (!error && user.coin >= 3) {
        alert.success("Fal başarıyla gönderildi!", {
          timeout: 2000,
        });
        setTimeout(() => {
          navigate("/fallarim");
        }, 2000);
      } else {
        alert.error("Mağaza sayfasından jeton almalısın!", {
          timeout: 2000,
        });
      }
    }
  };

  return (
    <div className="min-h-[100vh] flex flex-col items-center bg-[#FAFAFA]">
      <div className="w-full bg-white flex items-center justify-center border-b-[1px] border-solid border-[rgba(0,0,0,0.07)] px-2">
        <div className="w-full relative max-w-md py-4 text-center text-lg font-semibold">
          <Link to={"/"} className="arrow-left left-4"></Link>
          Rüya Yorumu
        </div>
      </div>
      <div className="flex flex-col items-center max-w-md w-full bg-[#FAFAFA] tracking-[-0.02em] px-2">
        <textarea
          className="w-full py-2 pl-4 mt-6 border-[1px] border-solid border-[rgba(0,0,0,0.07)] rounded-md outline-none font-normal text-sm text-black"
          placeholder="Rüyanı Anlat"
          rows={4}
          onChange={(event) => setDream(event.target.value)}
        />
        <input
          className="w-full user-blocked-color py-2 pl-4 mt-6 border-[1px] border-solid border-[rgba(0,0,0,0.07)] rounded-md outline-none font-normal text-sm text-black"
          placeholder="Adınız"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <Link
          className="mt-6 text-center font-normal text-md text-[#9E9E9D]"
          to="/profili-tamamla"
        >
          Bilgilerini güncellemek için buraya tıkla
        </Link>
        <button
          type="button"
          className="w-full max-w-md py-3 mt-8 bg-[#49111C] text-white rounded-lg border-none outline-none font-semibold text-md leading-5 cursor-pointer"
          onClick={handleSubmit}
        >
          Gönder (3 Jeton)
        </button>
      </div>
      <style jsx="true">{`
        .arrow-left {
          box-sizing: border-box;
          position: absolute;
          display: block;
          transform: scale(var(--ggs, 1));
          width: 22px;
          height: 22px;
          top: calc(50% - 11px);
        }
        .arrow-left::after,
        .arrow-left::before {
          content: "";
          display: block;
          box-sizing: border-box;
          position: absolute;
          left: 3px;
        }
        .arrow-left::after {
          width: 8px;
          height: 8px;
          border-bottom: 2px solid;
          border-left: 2px solid;
          transform: rotate(45deg);
          bottom: 7px;
        }
        .arrow-left::before {
          width: 16px;
          height: 2px;
          bottom: 10px;
          background: currentColor;
        }
        .succes-container {
          visibility: hidden;
          transition: visibility 0.1s ease-in-out;
        }
        .success-container-bg {
          backdrop-filter: blur(4px);
          opacity: 0;
          transition: opacity 0.1s ease-in-out;
        }
        .success-card {
          transform: translateY(-50%);
          bottom: 50%;
          transition: all 0.1s ease-in-out 0.1s;
          box-shadow: 0px 6px 14px -6px rgba(0, 0, 0, 0.12),
            0px 10px 32px -4px rgba(0, 0, 0, 0.1);
        }
        .succes-container.active {
          visibility: visible;
        }
        .succes-container.active .success-container-bg {
          opacity: 1;
        }
        .succes-container.active .success-card {
          transform: translateY(0%);
          bottom: 0%;
        }
      `}</style>
    </div>
  );
};

export default DreamInterpretation;
