import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import imageCompression from "browser-image-compression";
import { storage } from ".";

export const saveImage = async (file) => {
  const [compressedFile, error] = await compressImagePng(file);

  const promise = new Promise((resolve, reject) => {
    try {
      const storageRef = ref(storage, `${Date.now()}.png`);
      const uploadTask = uploadBytesResumable(storageRef, compressedFile);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // const prog = Math.round(
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // );
        },
        (error) => reject([null, error]),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve([downloadURL, null]);
          });
        }
      );
    } catch (error) {
      reject([null, error]);
    }
  });

  if (error) {
    return [null, error];
  } else {
    return await promise;
  }
};

const compressImagePng = async (imageFile) => {
  const options = {
    fileType: "image/png", // optional, fileType override e.g., 'image/jpeg', 'image/png' (default: file.type)
    initialQuality: 0.75, // optional, initial quality value between 0 and 1 (default: 1)
    maxWidthOrHeight: 1280, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
    // but, automatically reduce the size to smaller than the maximum Canvas size supported by each browser.
    // Please check the Caveat part for details.
  };
  try {
    const compressedFile = await imageCompression(imageFile, options);

    return [compressedFile, null];
  } catch (error) {
    return [null, error];
  }
};
