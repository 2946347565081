export const ConfidentialityAgreement = () => {
  return (
    <div className="h-[100vh] flex flex-col items-center bg-white">
      <div className="w-full flex justify-center px-8">
        <div className="w-full max-w-md py-4 pl-4 text-lg font-semibold text-center">
          Gizlilik Sözleşmesi
        </div>
      </div>
      <div className="w-full flex justify-center bg-[#FAFAFA] h-full">
        <div className="w-full max-w-md flex flex-col items-center p-4">
          İş bu Kullanım Şartları, bifallasana uygulaması ile (bundan böyle
          bifallasana olarak anılacaktır) tüm özel ve tüzel kişi ve kurumlar
          (bundan böyle KULLANICI olarak anılacaktır) arasındaki kullanım
          şartlarını içermektedir. Bu Gizlilik Politikası, KULLANICIların hangi
          bilgilerinin nasıl toplandığını, ne için kullanılacağını ve
          KULLANICIların hangi seçeneklere sahip olduklarını açıklamaktadır.
          Toplanan bilgiler: Mobil cihazınıza bifallasana uygulamasını
          yüklediğinizde, aşağıda listelenen bilgileri topluyoruz: Cihaz bilgisi
          (model ve cihaz numarası), ağ kalitesi ile ilgili çeşitli performans
          parametreleri (ör. Sinyal seviyesi, link hızı), cihaz tüm uygulama
          kullanım bilgisi ve oto üyelik seçilirse eşsiz cihaz ID'si. Bu
          bilgiler sadece üyelik girişlerini hızlandırmak için kullanılır.
          Kesinlikle paylaşılmaz ve değiştirilemez. Facebook ve Google hesabı
          ile girişlerde Facebook mail adresi,ismi ve Google Mail adresi
          sunucularımızda saklanır. bifallasana uygulamasında hesabınızda
          yapılan değişikliklerde durumlarda kayıtlı tüm telefonlarınıza
          bildirim gider ve email gönderilir. Bu sizin dışınızda olan bir
          durumsa bilgilendirilmiş olursunuz. Bu bilgiler kişisel bilgi değildir
          ve asla kişiselleştirilemez. bifallasana uygulamasını kullanırken
          girmiş olduğunuz ad, doğum tarihi, cinsiyet, ilişki durumu gibi
          kişisel bilgiler ayrıca kayıt altına alınmaktadır. Toplanan bilgiyi
          nasıl kullanıyoruz Bu toplanan bilgilerden kümelenmiş anonim bilgi
          yaratarak, belli zaman, veri kullanımı ile ilgili cihaz
          etkinliklerinde ve belli ağ durumlarında, internet bağlantısı deneyimi
          ve ağ kalitesini anlamak ve bu bilgilere göre de mobil operatörlerin
          veri hizmetlerini ve kullanım koşullarında açıklanan uygulama
          hizmetlerini pazarlamak maksadıyla kullanıyoruz. bifallasana
          uygulamasını kullanırken girmiş olduğunuz kişisel bilgilerinizi
          bifallasana uygulamasının sunduğu hizmetlerin hazırlanmasında
          kullanmaktayız. Kümelenmiş bilgiyi nasıl kullanıyoruz Anonim,
          istatistiksel veya kümelenmiş bilgileri sizin internet bağlantı
          deneyiminizi yükseltmek adına, ticari, istatistiksel amaçlar için ve
          yeni servisler ve özellikleri yaratmak ile servisin kalitesini
          artırmak için kullanabiliriz. Bu anonim, istatistiksel veya kümelenmiş
          bilgiler, kullanıcıları olan iş ortakları veya 3. partilerle birlikte
          kullanılabilir. Bu bilgiler KULLANICIların kişisel bilgilerini
          kesinlikle içermez. bifallasana KULLANICIlarına ait kişisel veriler,
          6698 sayılı Kişisel Verilerin Korunması kanunu esaslarına göre,
          KULLANICIlar kişisel bilgilerinin kullanılmasına izin verdiği
          takdirde, bifallasana Uygulaması Kullanım Koşullarında belirtilen
          amaçlar için gerekli kapsam ve sürede işlenebilir ve saklanabilir.
          bifallasana üyelerin bilgilerini, yasal mercilerden resmi bir istek
          gelmesi, bifallasana'nın haklarını korumak veya üyelik sözleşmesinde
          kabul edilen durumlar haricinde gizli tutacağını beyan eder.
          -bifallasana üyeleri bilgilerini istedikleri zaman düzenleyebilirler.
          Kullanım koşullarına uymayan bilgileri yönetim istediği zaman silme
          hakkına veya o üyenin üyeliğini iptal etme hakkına sahiptir.
          -bifallasana üyeleri istedikleri zaman üyeliklerini iptal edebilirler.
          bifallasana bu metindeki içeriği istediği zaman değiştirebileceğiniz
          beyan eder. Kullanıcı Verilerinin Tutulması ve Silinmesi Hakkında
          bifallasana’da paylaştığınız bilgiler size aittir. Uygulama içerisinde
          bu bilgileri kiminle paylaşacağınıza siz karar verirsiniz. Paylaşmakta
          olduğunuz bilgileri size uygulama içerisinde sunduğumuz araçlar
          vasıtasıyla silebilirsiniz, böylelikle paylaşmayı durdurabilirsiniz.
          Sildiğiniz veya paylaşmayı durdurduğunuz bilgileri sunucularımızdan da
          kaldırıyoruz. Hesabınızı istediğiniz an silebilirsiniz. Yine de
          bununla ilgili bir sorun yaşarsanız, destek ekibimiz ile
          info@bifallasana.com üzerinden iletişime geçip bilgilerinizin ve
          hesabınızın silinmesini talep edebilirsiniz. Kullanıcılar, bifallasana
          uygulaması ile hangi bilgileri paylaşacağı konusunda karar sahibidir.
          bifallasana uygulaması, kullanıcının kendisine vermiş olduğu yetki
          haricinde başka bir bilgiye kullanıcıdan izinsiz olarak erişemez.
          Kullanıcılar, dilediği takdirde uygulama ile paylaştığı bilgileri
          silme ve değiştirme hakkına sahiptir. Bununla birlikte, kullanıcılar
          üyelik hesaplarını silme hakkına da sahiptir. Üyelik hesabını silmek
          isteyen kullanıcılar, uygulamada bulunan “Profil Ayarları” bölümündeki
          dikey olarak ölçeklenen üç noktaya tıklayarak “Üyeliğimi İptal Et”
          seçeceğini kullanabilir. Bu bölüm, aşağıdaki ekran görüntüsünde net
          olarak görüntülenmektedir. Üyelik hesabını sonlandıran kullanıcıların
          bilgileri, kullanıcının üyeliğinin sona ermesiyle birlikte sunucu
          tarafında da silinmektedir. Ek olarak, kullanıcıların bifallasana
          uygulamasındaki forum bölümünde paylaştığı gönderiler ve görseller,
          yaptıkları yorumlar, kullanıcıların birbirlerine gönderdikleri
          mesajlar kullanıcılar tarafından silindiği takdirde sunucularımızda ve
          uygulamada da silinmektedir. Kullanıcıların fal gönderirken kendi
          rızaları ile paylaşmış oldukları kişisel bilgiler, sadece fal
          baktırmak için tutulmakta olup kullanıcıların fal sonuçlarını silmesi
          halinde sunucularımızdan da silinmektedir
        </div>
      </div>
    </div>
  );
};

export default ConfidentialityAgreement;
