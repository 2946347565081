import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "../src/components/AuthProvider";
import PrivateRoute from "./components/PrivateRoute";
import CoffeeFortune from "./views/CoffeeFortune";
import { CompleteProfile } from "./views/CompleteProfile";
import ConfidentialityAgreement from "./views/ConfidentialityAgreement";
import DreamInterpretation from "./views/DreamInterpretation";
import EmailRegister from "./views/EmailRegister";
import ForgotPassword from "./views/ForgotPassword";
import LoginView from "./views/LoginView";
import MainView from "./views/MainView";
import MyFortunes from "./views/MyFortunes";
import MyProfile from "./views/MyProfile";
import PaytrIframe from "./views/PaytrIframe";
import RelationshipAnalysis from "./views/RelationshipAnalysis";
import StoreView from "./views/StoreView";
import TarotFortune from "./views/TarotFortune";
import TermsOfUse from "./views/TermsOfUse";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/odeme" element={<PaytrIframe />} />
          <Route path="/giris-yap" element={<LoginView />} />
          <Route path="/parolami-unuttum" element={<ForgotPassword />} />
          <Route path="/eposta-ile-kayit-ol" element={<EmailRegister />} />
          <Route
            path="/gizlilik-sozlesmesi"
            element={<ConfidentialityAgreement />}
          />
          <Route path="/kullanim-sartlari" element={<TermsOfUse />} />
          <Route path="/eposta-ile-kayit-ol" element={<EmailRegister />} />
          <Route exact path="/" element={<PrivateRoute />}>
            <Route exact path="/" element={<MainView />} />
          </Route>
          <Route exact path="/magaza" element={<PrivateRoute />}>
            <Route exact path="/magaza" element={<StoreView />} />
          </Route>
          <Route exact path="/fallarim" element={<PrivateRoute />}>
            <Route exact path="/fallarim" element={<MyFortunes />} />
          </Route>
          <Route exact path="/profilim" element={<PrivateRoute />}>
            <Route exact path="/profilim" element={<MyProfile />} />
          </Route>
          <Route exact path="/kahve-fali" element={<PrivateRoute />}>
            <Route exact path="/kahve-fali" element={<CoffeeFortune />} />
          </Route>
          <Route exact path="/ruya-yorumu" element={<PrivateRoute />}>
            <Route
              exact
              path="/ruya-yorumu"
              element={<DreamInterpretation />}
            />
          </Route>
          <Route exact path="/iliski-analizi" element={<PrivateRoute />}>
            <Route
              exact
              path="/iliski-analizi"
              element={<RelationshipAnalysis />}
            />
          </Route>
          <Route exact path="/tarot-fali" element={<PrivateRoute />}>
            <Route exact path="/tarot-fali" element={<TarotFortune />} />
          </Route>
          <Route exact path="/profili-tamamla" element={<PrivateRoute />}>
            <Route
              exact
              path="/profili-tamamla"
              element={<CompleteProfile />}
            />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
