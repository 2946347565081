import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import iconCamera from "../assets/images/camera.svg";
import iconProfile from "../assets/images/icon-profile.svg";
import { AuthContext } from "../components/AuthProvider";
import { completeProfile } from "../firebase/db";
import { saveImage } from "../firebase/storage";

export const CompleteProfile = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [allowMail, setAllowMail] = useState(currentUser.allowMail);
  const [gender, setGender] = useState(currentUser.gender);
  const [name, setName] = useState(currentUser.name);
  const [imageUrl, setImageUrl] = useState(
    currentUser.photo && currentUser.photo !== ""
      ? currentUser.photo
      : iconProfile
  );

  async function onImageChange(e) {
    const [photo] = await saveImage(e.target.files[0]);
    setImageUrl(photo);
  }

  const onGenderChange = (e) => {
    setGender(e.target.value);
  };

  const onSubmit = async () => {
    await completeProfile(
      currentUser.uid,
      name,
      gender,
      allowMail,
      imageUrl !== iconProfile ? imageUrl : ""
    );
    navigate("/");
  };

  return (
    <div className="min-h-[100vh] flex flex-col items-center bg-[#FAFAFA] pt-8">
      <div className="w-full flex justify-center border-b-[1px] border-solid border-[rgba(0,0,0,0.07)] px-2">
        <div className="w-full max-w-md py-2 pl-4 text-lg font-semibold">
          Profili Tamamla
        </div>
      </div>
      <div className="flex flex-col items-center max-w-md w-full px-2">
        <div className="w-[100px] h-[100px] relative mt-3 rounded-full">
          <img
            className="w-full h-full object-cover rounded-full"
            src={imageUrl}
            alt=""
          />
          <div className="w-[3rem] h-[3rem] bg-[#521423] flex justify-center items-center rounded-full absolute bottom-[-1rem] right-0">
            <img className="w-6" src={iconCamera} alt="" />
            <input
              type="file"
              accept="image/*"
              className="w-full h-full absolute top-0 right-0 left-0 bottom-0 z-20 opacity-0 cursor-pointer"
              onChange={onImageChange}
            ></input>
          </div>
          <input
            type="file"
            accept="image/*"
            className="w-full h-full absolute top-0 right-0 left-0 bottom-0 z-20 opacity-0 cursor-pointer"
            onChange={onImageChange}
          ></input>
        </div>
        <input
          className="w-full py-2 pl-4 mt-6 border-[1px] border-solid border-[rgba(0,0,0,0.07)] rounded-md outline-none font-normal text-sm text-black"
          id="username"
          type="text"
          placeholder="username"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <div className="flex w-full mt-4">
          <div className="radio-button relative w-full bg-white rounded-l-md border-[1px] border-solid border-[#5E3C46]">
            <input
              className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
              name="gender"
              type="radio"
              value="Male"
              checked={gender === "Male"}
              onChange={onGenderChange}
            />
            <div className="w-full h-full py-1 text-[#874E2B] font-medium text-sm flex justify-center items-center">
              Erkek
            </div>
          </div>
          <div className="radio-button relative w-full bg-white border-y-[1px] border-solid border-[#5E3C46]">
            <input
              className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
              name="gender"
              type="radio"
              value="Female"
              checked={gender === "Female"}
              onChange={onGenderChange}
            />
            <div className="w-full h-full py-1 text-[#874E2B] font-medium text-sm flex justify-center items-center">
              Kadın
            </div>
          </div>
          <div className="radio-button relative w-full bg-white rounded-r-md border-[1px] border-solid border-[#5E3C46]">
            <input
              className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
              name="gender"
              type="radio"
              value="Undefined"
              checked={gender === "Undefined"}
              onChange={onGenderChange}
            />
            <div className="w-full h-full py-1 text-[#874E2B] font-medium text-sm flex justify-center items-center">
              Belirtilmemiş
            </div>
          </div>
        </div>
        <div className="w-full flex items-center mt-4">
          <input
            type="checkbox"
            checked={allowMail}
            onChange={() => setAllowMail(!allowMail)}
            id="allow-mail"
            className="mr-4"
          />
          <div>Mail almak istiyorum.</div>
        </div>
        <button
          onClick={onSubmit}
          className="w-full max-w-md py-3 mt-8 bg-[#49111C] text-white rounded-lg border-none outline-none font-semibold text-md leading-5"
        >
          Tamamla
        </button>
      </div>
      <style jsx="true">{`
        input:checked ~ div {
          background-color: #87431e;
          color: #fff;
        }
        #allow-mail ~ div {
          background-color: transparent;
          color: #000;
        }
      `}</style>
    </div>
  );
};

export default CompleteProfile;
