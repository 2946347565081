import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import coin from "../assets/images/coin.png";
import { AuthContext } from "../components/AuthProvider";
import NavigationBar from "../components/NavigationBar";
import { getPaytrToken } from "../services/index";

export const StoreView = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  const handleClick = async (payment_amount, user_basket, coin) => {
    user_basket = JSON.stringify(user_basket);
    const [data, error] = await getPaytrToken(
      currentUser,
      coin,
      currentUser.email,
      payment_amount,
      user_basket,
      currentUser.name,
      "address",
      5555555555
    );

    if (!error) {
      navigate("/odeme", {
        state: {
          iframeToken: data.iframeToken,
        },
      });
    }
  };

  return (
    <div className="min-h-[100vh] flex flex-col items-center text-center bg-[#FAFAFA]">
      <div className="w-full flex justify-center px-8 bg-white">
        <div className="w-full max-w-md py-4 pl-4 text-lg font-semibold">
          Mağaza
        </div>
      </div>
      <div className="w-full flex justify-center pb-24">
        <div className="flex flex-wrap justify-between items-center max-w-md w-full px-2 pb-4">
          <div
            onClick={() => handleClick(3499, [["5 Jeton", "34.99", 1]], 5)}
            className="relative px-6 mt-6 w-[48%] min-h-[10rem] py-6 bg-cover rounded-2xl bg-center flex flex-col items-center border-[1px] border-solid border-[rgba(0,0,0,0.07)] cursor-pointer"
          >
            <img className="w-[76px] h-auto" src={coin} alt="" />
            <span className="text-black text-lg font-semibold">5 Jeton</span>
            <span className="text-[#7B3A21] text-lg font-semibold">₺34,99</span>
          </div>
          <div
            onClick={() => handleClick(7099, [["10 Jeton", "70.99", 1]], 10)}
            className="relative px-6 mt-6 w-[48%] min-h-[10rem] py-6 bg-cover rounded-2xl bg-center flex flex-col items-center border-[1px] border-solid border-[rgba(0,0,0,0.07)] cursor-pointer"
          >
            <img className="w-[76px] h-auto" src={coin} alt="" />
            <span className="text-black text-lg font-semibold">10 Jeton</span>
            <span className="text-[#7B3A21] text-lg font-semibold">₺70,99</span>
          </div>
          <div
            onClick={() => handleClick(10499, [["15 Jeton", "104.99", 1]], 15)}
            className="relative px-6 mt-6 w-[48%] min-h-[10rem] py-6 bg-cover rounded-2xl bg-center flex flex-col items-center border-[1px] border-solid border-[rgba(0,0,0,0.07)] cursor-pointer"
          >
            <img className="w-[76px] h-auto" src={coin} alt="" />
            <span className="text-black text-lg font-semibold">15 Jeton</span>
            <span className="text-[#7B3A21] text-lg font-semibold">104,99</span>
          </div>
          <div
            onClick={() => handleClick(17499, [["25 Jeton", "174.99", 1]], 25)}
            className="relative px-6 mt-6 w-[48%] min-h-[10rem] py-6 bg-cover rounded-2xl bg-center flex flex-col items-center border-[1px] border-solid border-[rgba(0,0,0,0.07)] cursor-pointer"
          >
            <img className="w-[76px] h-auto" src={coin} alt="" />
            <span className="text-black text-lg font-semibold">25 Jeton</span>
            <span className="text-[#7B3A21] text-lg font-semibold">
              174,99
            </span>
          </div>
          <div
            onClick={() => handleClick(24999, [["35 Jeton", "249.99", 1]], 35)}
            className="relative px-6 mt-6 w-[48%] min-h-[10rem] py-6 bg-cover rounded-2xl bg-center flex flex-col items-center border-[1px] border-solid border-[rgba(0,0,0,0.07)] cursor-pointer"
          >
            <img className="w-[76px] h-auto" src={coin} alt="" />
            <span className="text-black text-lg font-semibold">35 Jeton</span>
            <span className="text-[#7B3A21] text-lg font-semibold">
              249,99
            </span>
          </div>
        </div>
      </div>
      <NavigationBar />
    </div>
  );
};

export default StoreView;
