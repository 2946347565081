import React, { useContext, useEffect, useState } from "react";
import standard from "../assets/images/standard.png";
import fast from "../assets/images/fast.png";
import super_fast from "../assets/images/super_fast.png";
import voice from "../assets/images/voice.png";
import video from "../assets/images/video.png";
import { getCoffeeOptions, getUserData } from "../firebase/db";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./AuthProvider";
import { useAlert } from "react-alert";

const FortuneOptions = ({ selectFortuneOption }) => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const images = { standard, fast, super_fast, voice, video };
  const [coffeeOptions, setCoffeeOptions] = useState([]);
  const alert = useAlert();

  useEffect(() => {
    const getCoffeeOptionsData = async () => {
      const [data, error] = await getCoffeeOptions();
      if (error) {
        console.error(error);
      } else {
        setCoffeeOptions(data);
      }
    };

    getCoffeeOptionsData();
  }, []);

  const handleClick = async (coffeeOption) => {
    const [user, error] = await getUserData(currentUser.uid);
    if (!error && user.coin >= coffeeOption.coin) {
      selectFortuneOption(coffeeOption);
    } else {
      alert.error("Mağaza sayfasından jeton almalısın!", {
        timeout: 2000,
      });
    }
  };

  return (
    <div className="min-h-[100vh] flex flex-col items-center bg-[#FAFAFA]">
      <div className="w-full flex items-center justify-center border-b-[1px] border-solid border-[rgba(0,0,0,0.07)] px-2">
        <div className="w-full relative max-w-md py-4 text-center text-lg font-semibold">
          <div
            className="arrow-left left-4 cursor-pointer"
            onClick={() => navigate(-1)}
          ></div>
          Fal Opsiyonları
        </div>
      </div>
      <div className="flex flex-col items-center max-w-md bg-[#FAFAFA] w-full px-2 pb-4">
        {coffeeOptions.map((coffeeOption) => {
          return (
            <div
              onClick={() => handleClick(coffeeOption)}
              key={coffeeOption.title}
              className="flex flex-col items-start w-full bg-[#F5F5F5] mt-4 rounded-lg p-4 cursor-pointer"
            >
              <img
                className="w-[40px]"
                src={
                  images[
                    coffeeOption.icon.replace("assets/", "").replace(".png", "")
                  ]
                }
                alt=""
              />
              <div className="text-lg font-semibold mt-2">
                {coffeeOption.title}
              </div>
              <div className="text-left mt-2 text-[#B1B1B1] text-sm font-semibold">
                {coffeeOption.subtitle}
              </div>
              <button className="w-full max-w-[6rem] py-3 mt-2 bg-[#49111C] text-white rounded-lg border-none outline-none font-semibold text-md leading-5">
                {coffeeOption.coin} Jeton
              </button>
            </div>
          );
        })}
      </div>
      <style jsx="true">{`
        .arrow-left {
          box-sizing: border-box;
          position: absolute;
          display: block;
          transform: scale(var(--ggs, 1));
          width: 22px;
          height: 22px;
          top: calc(50% - 11px);
        }
        .arrow-left::after,
        .arrow-left::before {
          content: "";
          display: block;
          box-sizing: border-box;
          position: absolute;
          left: 3px;
        }
        .arrow-left::after {
          width: 8px;
          height: 8px;
          border-bottom: 2px solid;
          border-left: 2px solid;
          transform: rotate(45deg);
          bottom: 7px;
        }
        .arrow-left::before {
          width: 16px;
          height: 2px;
          bottom: 10px;
          background: currentColor;
        }
        .succes-container {
          visibility: hidden;
          transition: visibility 0.1s ease-in-out;
        }
        .success-container-bg {
          backdrop-filter: blur(4px);
          opacity: 0;
          transition: opacity 0.1s ease-in-out;
        }
        .success-card {
          transform: translateY(-50%);
          bottom: 50%;
          transition: all 0.1s ease-in-out 0.1s;
          box-shadow: 0px 6px 14px -6px rgba(0, 0, 0, 0.12),
            0px 10px 32px -4px rgba(0, 0, 0, 0.1);
        }
        .succes-container.active {
          visibility: visible;
        }
        .succes-container.active .success-container-bg {
          opacity: 1;
        }
        .succes-container.active .success-card {
          transform: translateY(0%);
          bottom: 0%;
        }
      `}</style>
    </div>
  );
};
export default FortuneOptions;
