import React from "react";
import { useNavigate } from "react-router-dom";
import iconSms from "../assets/images/sms-notification.svg";
import { forgotPassword } from "../firebase/auth";

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.elements.email.value;
    const [result, error] = await forgotPassword(email);

    if (!error && result) {
      navigate("/giris-yap");
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="h-[100vh] flex flex-col items-center bg-[#FAFAFA]"
    >
      <div className="w-full flex items-center justify-center border-b-[1px] border-solid border-[rgba(0,0,0,0.07)] px-2">
        <div className="w-full relative max-w-md py-4 text-center text-lg font-semibold">
          <div className="arrow-left left-4 cursor-pointer" onClick={() => navigate(-1)}></div>
          Parolamı Unuttum
        </div>
      </div>
      <div className="h-full flex flex-col justify-center items-center max-w-md w-full px-2">
        <img src={iconSms} alt="" />
        <div className="text-center font-normal text-sm text-[#9E9E9E] mt-6">
          Kayıt olduğun e-posta adresine gelen link ile şifreni kurtarabilirsin
        </div>
        <input
          className="w-full py-2 pl-4 mt-6 border-[1px] border-solid border-[rgba(0,0,0,0.07)] rounded-md outline-none font-normal text-sm text-black"
          name="email"
          type="email"
          placeholder="E-posta adresi"
          // onChange={(e) => setName(e.target.value)}
        />
        <button className="w-full max-w-md py-3 mt-48 bg-[#500A1B] text-white rounded-lg border-none outline-none font-semibold text-md leading-5">
          Gönder
        </button>
      </div>
      <style jsx="true">{`
        .arrow-left {
          box-sizing: border-box;
          position: absolute;
          display: block;
          transform: scale(var(--ggs, 1));
          width: 22px;
          height: 22px;
          top: calc(50% - 11px);
        }
        .arrow-left::after,
        .arrow-left::before {
          content: "";
          display: block;
          box-sizing: border-box;
          position: absolute;
          left: 3px;
        }
        .arrow-left::after {
          width: 8px;
          height: 8px;
          border-bottom: 2px solid;
          border-left: 2px solid;
          transform: rotate(45deg);
          bottom: 7px;
        }
        .arrow-left::before {
          width: 16px;
          height: 2px;
          bottom: 10px;
          background: currentColor;
        }
      `}</style>
    </form>
  );
};

export default ForgotPassword;
