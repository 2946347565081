import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createUserEmailPassword } from "../firebase/auth";
import { useAlert } from "react-alert";

export const EmailRegister = () => {
  const navigate = useNavigate();
  const [isAgree, setIsAgree] = useState(false);
  const alert = useAlert();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, gender, email, password, validPassword } = e.target.elements;
    const allowMail = true;

    if (password.value === validPassword.value) {
      alert.info("Kayit olusturuluyor");
      const [, error] = await createUserEmailPassword(
        email.value,
        password.value,
        name.value,
        gender.value,
        allowMail
      );
      alert.removeAll(alert);
      if (!error) {
        alert.success("Kayit olusturuldu", {
          timeout: 2000,
        });
        navigate("/giris-yap");
      } else {
        alert.error("Bilgileriniz kontrol ediniz");
        console.error("error:", error);
      }
    }
  };
  return (
    <form
      onSubmit={handleSubmit}
      className="min-h-[100vh] flex flex-col items-center bg-[#FAFAFA]"
    >
      <div className="w-full flex items-center justify-center border-b-[1px] border-solid border-[rgba(0,0,0,0.07)] px-2">
        <div className="w-full relative max-w-md py-4 text-center text-lg font-semibold">
          <div
            className="arrow-left left-4 cursor-pointer"
            onClick={() => navigate(-1)}
          ></div>
          Kayıt Ol
        </div>
      </div>
      <div className="flex flex-col items-center max-w-md w-full px-2">
        <input
          className="w-full py-2 pl-4 mt-6 border-[1px] border-solid border-[rgba(0,0,0,0.07)] rounded-md outline-none font-normal text-sm text-black"
          name="name"
          type="text"
          placeholder="Ad Soyad"
          required
        />
        <div className="flex w-full mt-4">
          <div className="radio-button relative w-full bg-white rounded-l-md border-[1px] border-solid border-[#5E3C46]">
            <input
              className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
              name="gender"
              type="radio"
              value="Male"
            />
            <div className="w-full h-full py-1 text-[#874E2B] font-medium text-sm flex justify-center items-center checked">
              Erkek
            </div>
          </div>
          <div className="radio-button relative w-full bg-white border-y-[1px] border-solid border-[#5E3C46]">
            <input
              className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
              name="gender"
              type="radio"
              value="Female"
            />
            <div className="w-full h-full py-1 text-[#874E2B] font-medium text-sm flex justify-center items-center checked">
              Kadın
            </div>
          </div>
          <div className="radio-button relative w-full bg-white rounded-r-md border-[1px] border-solid border-[#5E3C46]">
            <input
              className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
              name="gender"
              type="radio"
              value="Undefined"
              defaultChecked
            />
            <div className="w-full h-full py-1 text-[#874E2B] font-medium text-sm flex justify-center items-center checked">
              Belirtilmemiş
            </div>
          </div>
        </div>
        <input
          className="w-full py-2 pl-4 mt-4 border-[1px] border-solid border-[rgba(0,0,0,0.07)] rounded-md outline-none font-normal text-sm text-black"
          name="email"
          type="email"
          placeholder="E-posta Adresi"
          required
        />
        <input
          className="w-full py-2 pl-4 mt-4 border-[1px] border-solid border-[rgba(0,0,0,0.07)] rounded-md outline-none font-normal text-sm text-black"
          name="password"
          type="password"
          placeholder="Şifre"
          required
        />
        <input
          className="w-full py-2 pl-4 mt-4 border-[1px] border-solid border-[rgba(0,0,0,0.07)] rounded-md outline-none font-normal text-sm text-black"
          name="validPassword"
          type="password"
          placeholder="Şifre Tekrar"
          required
        />
        <div className="flex items-center mt-6">
          <div className="form-group">
            <input
              type="checkbox"
              checked={isAgree}
              onChange={() => setIsAgree(!isAgree)}
              id="agree"
            />
            <label htmlFor="agree"></label>
          </div>
          <div className="text-center font-normal text-xs text-[#9E9E9E]">
            Giriş yaparak
            <a className="cursor-pointer underline pl-1" href="/">
              Kullanım Şartları
            </a>{" "}
            ve
            <a className="cursor-pointer underline px-1" href="/">
              Gizlilik Sözleşmesini
            </a>
            kabul etmiş sayılırım.
          </div>
        </div>
        <button
          disabled={!isAgree}
          className="w-full max-w-md py-3 mt-6 disabled:bg-[#9E9E9E] bg-[#49111C] text-white rounded-lg border-none outline-none font-semibold text-md leading-5"
        >
          Tamamla
        </button>
      </div>
      <style jsx="true">{`
        .arrow-left {
          box-sizing: border-box;
          position: absolute;
          display: block;
          transform: scale(var(--ggs, 1));
          width: 22px;
          height: 22px;
          top: calc(50% - 11px);
        }
        .arrow-left::after,
        .arrow-left::before {
          content: "";
          display: block;
          box-sizing: border-box;
          position: absolute;
          left: 3px;
        }
        .arrow-left::after {
          width: 8px;
          height: 8px;
          border-bottom: 2px solid;
          border-left: 2px solid;
          transform: rotate(45deg);
          bottom: 7px;
        }
        .arrow-left::before {
          width: 16px;
          height: 2px;
          bottom: 10px;
          background: currentColor;
        }
        input:checked ~ .checked {
          background-color: #87431e;
          color: #fff;
        }

        .form-group input {
          padding: 0;
          height: initial;
          width: initial;
          margin-bottom: 0;
          display: none;
          cursor: pointer;
        }

        .form-group label {
          position: relative;
          cursor: pointer;
        }

        .form-group label:before {
          content: "";
          -webkit-appearance: none;
          background-color: transparent;
          border: 2px solid #7c7c7c;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
            inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
          padding: 8px;
          display: inline-block;
          position: relative;
          vertical-align: middle;
          cursor: pointer;
          margin-right: 3px;
          margin-left: 5px;
        }

        .form-group input:checked + label:after {
          content: "";
          display: block;
          position: absolute;
          top: 5px;
          left: 12px;
          width: 5px;
          height: 10px;
          border: solid #7c7c7c;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      `}</style>
    </form>
  );
};

export default EmailRegister;
