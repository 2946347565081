const apiUrl = process.env.REACT_APP_API_URL;

// Example POST method implementation:
async function postData(path = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(apiUrl + path, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });

  return response.json(); // parses JSON response into native JavaScript objects
}

export const getPaytrToken = async (
  user,
  coin,
  email,
  payment_amount,
  user_basket,
  user_name,
  user_address,
  user_phone
) => {
  try {
    const data = await postData("/get-token", {
      user,
      coin,
      user_ip: "127.0.0.1",
      merchant_oid: "INV" + Date.now(),
      email,
      payment_amount,
      user_basket,
      no_installment: "0",
      max_installment: "1",
      currency: "TL",
      test_mode: 0,
      user_name,
      user_address,
      user_phone,
    });

    return [data, null];
  } catch (error) {
    return [null, error];
  }
};
