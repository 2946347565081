import moment from "moment";
import "moment/locale/tr";
import { useContext, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import empty from "../assets/images/empty.svg";
import fast from "../assets/images/fast.png";
import clockIcon from "../assets/images/icon-clock.svg";
import standard from "../assets/images/standard.png";
import super_fast from "../assets/images/super_fast.png";
import video from "../assets/images/video.png";
import voice from "../assets/images/voice.png";
import { AuthContext } from "../components/AuthProvider";
import FortuneAnswer from "../components/FortuneAnswer";
import FortuneComment from "../components/FortuneComment";
import NavigationBar from "../components/NavigationBar";
import {
  getCoffeeRequests,
  getDreamRequests,
  getLoveRequests,
  getTarotRequests,
  givebackCoffeeFortune,
  givebackTarotFortune,
} from "../firebase/db";
moment().locale("tr");

export const MyFortunes = () => {
  const alert = useAlert();
  const { currentUser } = useContext(AuthContext);
  const icons = { standard, fast, super_fast, voice, video };
  const [fortunes, setFortunes] = useState([]);
  const [fortuneType, setFortuneType] = useState("Coffee");
  const [selectedFortune, setSelectedFortune] = useState(null);

  const onFortuneChange = (e) => {
    if (e.target.value !== fortuneType) {
      setFortunes([]);
      setFortuneType(e.target.value);
    }
  };

  useEffect(() => {
    const fetchCoffeeFortunes = async () => {
      const [requests, error] = await getCoffeeRequests(currentUser.uid);

      if (!error) {
        setFortunes(requests);
      }
    };

    const fetchTarotFortunes = async () => {
      const [requests, error] = await getTarotRequests(currentUser.uid);

      if (!error) {
        setFortunes(requests);
      }
    };

    const fetchDreamFortunes = async () => {
      const [requests, error] = await getDreamRequests(currentUser.uid);

      if (!error) {
        setFortunes(requests);
      }
    };
    const fetchLoveFortunes = async () => {
      const [requests, error] = await getLoveRequests(currentUser.uid);

      if (!error) {
        setFortunes(requests);
      }
    };

    const fetchs = {
      fetchCoffeeFortunes,
      fetchTarotFortunes,
      fetchDreamFortunes,
      fetchLoveFortunes,
    };

    setFortunes([]);
    fetchs[`fetch${fortuneType}Fortunes`]();
  }, [currentUser, fortuneType]);

  const handleGiveback = async (fortune) => {
    let isSuccess = false;
    if (fortuneType === "Coffee") {
      const [result] = await givebackCoffeeFortune(fortune);
      isSuccess = result;
    } else {
      const [result] = await givebackTarotFortune(fortune);
      isSuccess = result;
    }

    if (isSuccess) {
      alert.success("İade talebiniz başarıyla gönderildi!", {
        timeout: 2000,
      });
      fortune.giveback = true;
      const updatedFortunes = fortunes.map((f) => {
        if (f.id === fortune.id) {
          return fortune;
        } else {
          return f;
        }
      });
      setFortunes(updatedFortunes);
    }
  };

  return (
    <>
      {selectedFortune ? (
        fortuneType === "Coffee" || fortuneType === "Tarot" ? (
          <FortuneAnswer
            fortune={selectedFortune}
            back={() => setSelectedFortune(null)}
          ></FortuneAnswer>
        ) : (
          <FortuneComment
            fortune={selectedFortune}
            back={() => setSelectedFortune(null)}
            fortuneType={fortuneType}
          ></FortuneComment>
        )
      ) : (
        <div className="min-h-[100vh] flex flex-col items-center text-center bg-[#FAFAFA]">
          <div className="w-full flex justify-center px-8 bg-white">
            <div className="w-full max-w-md py-4 pl-4 text-lg font-semibold">
              Fallarım
            </div>
          </div>
          <div className="w-full flex justify-center pb-24">
            <div className="flex flex-wrap justify-between items-center max-w-md w-full px-2 pb-4">
              <div className="flex w-full mt-4">
                <div className="radio-button relative w-full bg-white rounded-l-md border-[1px] border-solid border-[#5E3C46]">
                  <input
                    className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                    name="fortune"
                    type="radio"
                    value="Coffee"
                    checked={fortuneType === "Coffee"}
                    onChange={onFortuneChange}
                  />
                  <div className="w-full h-full py-1 text-[#874E2B] font-medium text-sm flex justify-center items-center">
                    Kahve Falı
                  </div>
                </div>
                <div className="radio-button relative w-full bg-white border-y-[1px] border-solid border-[#5E3C46]">
                  <input
                    className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                    name="fortune"
                    type="radio"
                    value="Tarot"
                    checked={fortuneType === "Tarot"}
                    onChange={onFortuneChange}
                  />
                  <div className="w-full h-full py-1 text-[#874E2B] font-medium text-sm flex justify-center items-center">
                    Tarot Falı
                  </div>
                </div>
                <div className="radio-button relative w-full bg-white border-y-[1px] border-l-[1px] border-solid border-[#5E3C46]">
                  <input
                    className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                    name="fortune"
                    type="radio"
                    value="Dream"
                    checked={fortuneType === "Dream"}
                    onChange={onFortuneChange}
                  />
                  <div className="w-full h-full py-1 text-[#874E2B] font-medium text-sm flex justify-center items-center">
                    Rüya Yorumu
                  </div>
                </div>
                <div className="radio-button relative w-full bg-white rounded-r-md border-[1px] border-solid border-[#5E3C46]">
                  <input
                    className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                    name="fortune"
                    type="radio"
                    value="Love"
                    checked={fortuneType === "Love"}
                    onChange={onFortuneChange}
                  />
                  <div className="w-full h-full py-1 text-[#874E2B] font-medium text-sm flex justify-center items-center">
                    İlişki Analizi
                  </div>
                </div>
              </div>
              <div className="w-full px-2">
                {fortunes.length !== 0 ? (
                  fortuneType === "Coffee" || fortuneType === "Tarot" ? (
                    fortunes.map((fortune) => (
                      <div
                        key={fortune.id}
                        className="flex flex-col items-start justify-between w-full bg-[#F5F5F5] p-4 rounded-xl mt-6 cursor-pointer"
                        onClick={() =>
                          fortune.answered && setSelectedFortune(fortune)
                        }
                      >
                        <div className="flex items-center">
                          <span className="text-base font-semibold text-left mr-4">
                            {fortuneType === "Coffee"
                              ? "Kahve Falı"
                              : "Tarot Falı"}
                          </span>
                          <span className="text-[#A0A0A0] text-sm font-semibold">
                            {moment(fortune.createdAt.toDate()).format(
                              "DD MMMM, HH:mm"
                            )}
                          </span>
                        </div>
                        <div className="text-[#A0A0A0] text-sm font-semibold text-left mt-2">
                          {fortune.option.subtitle}
                        </div>
                        <div className="flex mt-2">
                          <div className="flex items-center mr-6">
                            <img
                              className="w-6 mr-2"
                              src={
                                icons[
                                  fortune.option.icon
                                    .replace("assets/", "")
                                    .replace(".png", "")
                                ]
                              }
                              alt=""
                            />
                            <div className="text-[#8A421D] text-base font-semibold leading-5 cup-text">
                              {fortune.option.title}
                            </div>
                          </div>
                          <div className="flex items-center mr-6">
                            <img className="w-6 mr-2" src={clockIcon} alt="" />
                            <div
                              className={`text-base font-semibold leading-5 cup-text ${
                                fortune.answered
                                  ? "text-[#8A421D]"
                                  : "text-[#A0A0A0]"
                              }`}
                            >
                              {fortune.answered ? "Cevaplandı" : "Bekliyor"}
                            </div>
                          </div>
                        </div>
                        {moment
                          .duration(moment().diff(fortune.createdAt.toDate()))
                          .asHours() > fortune.option.hour &&
                        !fortune.answered ? (
                          <button
                            className="w-full max-w-md py-3 mt-2 bg-transparent text-[#65424A] rounded-full border-[1px] border-[#65424A] outline-none font-semibold text-md leading-5"
                            onClick={
                              !fortune.giveback
                                ? () => handleGiveback(fortune)
                                : () => false
                            }
                          >
                            İade {fortune.giveback ? "Bekleniyor" : "İste"}
                          </button>
                        ) : null}
                      </div>
                    ))
                  ) : (
                    fortunes.map((fortune) => (
                      <div
                        className="flex items-center justify-between w-full bg-[#F5F5F5] p-4 rounded-xl mt-6 cursor-pointer"
                        onClick={() =>
                          fortune.answered && setSelectedFortune(fortune)
                        }
                      >
                        <div className="flex flex-col items-start justify-between">
                          <span className="text-base font-semibold text-left mr-4">
                            {fortune.user.name}
                          </span>
                          <div className="text-[#A0A0A0] text-sm font-semibold text-left mt-2">
                            {fortune.answered ? "Yanıtlandı" : "Yanıt Bekliyor"}
                          </div>
                        </div>
                        <div className="text-sm font-semibold text-left text-[#242424] mr-4">
                          {moment(fortune.createdAt.toDate()).format(
                            "DD MMMM, HH:mm"
                          )}
                        </div>
                      </div>
                    ))
                  )
                ) : (
                  <div className="w-full flex flex-col justify-center items-center h-[calc(100vh-13.625rem)]">
                    <img className="w-20" src={empty} alt="" />
                    <div className="mt-6 text-[#B1B1B1] text-md font-semibold">
                      Aradığın kriterlerde bilgi bulunamadı
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <NavigationBar />
          <style jsx="true">{`
            input:checked ~ div {
              background-color: #87431e;
              color: #fff;
            }
          `}</style>
        </div>
      )}
    </>
  );
};

export default MyFortunes;
