import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../components/AuthProvider";
import { sendLoveRequest } from "../firebase/db";
import { saveImage } from "../firebase/storage";
import { getUserData } from "../firebase/db";
import { useAlert } from "react-alert";

export const RelationshipAnalysis = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [boyPhoto, setBoyPhoto] = useState("");
  const [boyName, setBoyName] = useState("");
  const [boyBirthday, setBoyBirthday] = useState("");
  const [girlPhoto, setGirlPhoto] = useState("");
  const [girlName, setGirlName] = useState("");
  const [girlBirthday, setGirlBirthday] = useState("");
  const [startedDate, setStartedDate] = useState("");
  const alert = useAlert();

  const handleImageChange = async (e, isBoy) => {
    alert.info("Fotoğraf yükleniyor");
    const [imageUrl, error] = await saveImage(e.target.files[0]);
    alert.removeAll(alert);

    if (!error) {
      if (isBoy) {
        setBoyPhoto(imageUrl);
      } else {
        setGirlPhoto(imageUrl);
      }
    }
  };

  const handleSubmit = async () => {
    if (
      girlName === "" ||
      boyName === "" ||
      girlPhoto === "" ||
      boyPhoto === "" ||
      girlBirthday === "" ||
      boyBirthday === "" ||
      startedDate === ""
    ) {
      alert.error("Lütfen tüm alanları doldurunuz.", {
        timeout: 2000,
      });
    } else {
      const [user, error] = await getUserData(currentUser.uid);
      if (!error && user.coin >= 3) {
        const [, error] = await sendLoveRequest(
          currentUser.uid,
          girlName,
          boyName,
          girlPhoto,
          boyPhoto,
          girlBirthday,
          boyBirthday,
          startedDate
        );

        if (!error) {
          alert.success("Fal başarıyla gönderildi!", {
            timeout: 2000,
          });
          setTimeout(() => {
            navigate("/fallarim");
          }, 2000);
        }
      } else {
        alert.error("Mağaza sayfasından jeton almalısın!", {
          timeout: 2000,
        });
      }
    }
  };

  return (
    <div className="min-h-[100vh] flex flex-col items-center bg-[#FAFAFA]">
      <div className="w-full bg-white flex items-center justify-center border-b-[1px] border-solid border-[rgba(0,0,0,0.07)] px-2">
        <div className="w-full relative max-w-md py-4 text-center text-lg font-semibold">
          <div
            className="arrow-left left-4 cursor-pointer"
            onClick={() => navigate(-1)}
          ></div>
          İlişki Analizi
        </div>
      </div>
      <div className="flex flex-col max-w-md w-full bg-[#FAFAFA] tracking-[-0.02em] px-2 py-4">
        <div className="w-full flex justify-between">
          <div className="flex flex-col justify-center w-[47%]">
            <div className="w-full h-[300px] flex justify-center items-center border-[1px] border-solid border-[#A6A6A6] rounded-2xl relative">
              <input
                type="file"
                accept="image/*"
                className="w-full h-full absolute top-0 left-0 right-0 bottom-0 opacity-0 cursor-pointer z-30"
                onChange={(e) => handleImageChange(e, true)}
              ></input>
              <img
                className={`h-full ${
                  boyPhoto !== "" ? "w-full" : ""
                } object-cover rounded-2xl`}
                src={boyPhoto}
                alt=""
              />
              <div
                className={`w-8 h-8 relative ${
                  boyPhoto !== "" ? "hidden" : ""
                }`}
              >
                <svg
                  viewBox="0 0 512 512"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M234 0.999939C202.8 3.89993 169.8 13.0999 143.5 26.1999C131.7 32.0999 115 42.1999 115 43.3999C115 44.8999 164.7 198.1 165.4 198.7C166.2 199.5 164.3 200.9 280.3 116.5L389 37.4999L385.2 35.1999C378.3 30.8999 361.6 22.6999 350.5 18.2999C331.8 10.7999 309 4.89993 287.5 1.99994C276.4 0.499939 245.5 -0.100067 234 0.999939Z"
                    fill="#4B0E1B"
                  />
                  <path
                    d="M348.5 103.9C312.2 130.3 282.5 152.2 282.6 152.6C282.7 153.1 332.6 189.7 393.6 234C466.3 286.9 504.7 314.2 505.2 313.5C505.6 312.8 506.8 307.2 507.9 300.9C523.6 212.4 492.1 123 424.1 63.4001C419.5 59.3001 415.5 56.0001 415.1 56.0001C414.8 56.0001 384.8 77.6001 348.5 103.9Z"
                    fill="#4B0E1B"
                  />
                  <path
                    d="M77.8 72.7C56.6 93.4 39.2 117.5 26.5 143.5C17.2 162.3 12.8 174.5 7.99996 193.5C-2.30004 234.5 -2.60004 274.5 7.09996 315.7L9.29996 325H91.7C136.9 325 174 324.7 174 324.2C174.1 322.1 89.4 63 88.6 63C88.1 63 83.2 67.4 77.8 72.7Z"
                    fill="#4B0E1B"
                  />
                  <path
                    d="M354.6 272.2C350.4 285 331.3 343.9 312.1 403C292.9 462.1 277.3 510.7 277.6 510.9C277.8 511.2 282.4 510.8 287.8 510.1C327.6 505 366.4 490.3 399.8 467.8C426.9 449.5 455.1 420.2 473.2 391.4C479.4 381.5 491.8 356.9 494.4 349.5L495.8 345.3L429.9 297.4C393.7 271 363.6 249.4 363.1 249.2C362.5 249 358.7 259.4 354.6 272.2Z"
                    fill="#4B0E1B"
                  />
                  <path
                    d="M20.9 357.4C23.1 363 34.3 384.2 39.4 392.3C53.2 414.2 75.3 439 95.5 455.3C135.5 487.6 191.4 509.5 238.4 511.2L245.3 511.5L270.6 433.5C284.6 390.6 296 355.4 296 355.2C296 355.1 233.9 355 158 355H20L20.9 357.4Z"
                    fill="#4B0E1B"
                  />
                </svg>
              </div>
            </div>
            <input
              className="w-full py-2 pl-4 mt-6 border-[1px] border-solid border-[rgba(0,0,0,0.07)] rounded-md outline-none font-normal text-sm text-black"
              placeholder="Ad Soyad"
              onChange={(e) => setBoyName(e.target.value)}
            />
            <input
              className="w-full py-2 pl-4 mt-6 border-[1px] border-solid border-[rgba(0,0,0,0.07)] rounded-md outline-none font-normal text-sm text-black"
              placeholder="Doğum Tarihi"
              onChange={(e) => setBoyBirthday(e.target.value)}
            />
          </div>
          <div className="flex flex-col justify-center w-[47%]">
            <div className="w-full h-[300px] flex justify-center items-center border-[1px] border-solid border-[#A6A6A6] rounded-2xl relative">
              <img
                className={`h-full ${
                  girlPhoto !== "" ? "w-full" : ""
                } object-cover rounded-2xl`}
                src={girlPhoto}
                alt=""
              />
              <input
                type="file"
                accept="image/*"
                className="w-full h-full absolute top-0 left-0 right-0 bottom-0 opacity-0 cursor-pointer z-30"
                onChange={(e) => handleImageChange(e, false)}
              ></input>
              <div
                className={`w-8 h-8 relative ${
                  girlPhoto !== "" ? "hidden" : ""
                }`}
              >
                <svg
                  viewBox="0 0 512 512"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M234 0.999939C202.8 3.89993 169.8 13.0999 143.5 26.1999C131.7 32.0999 115 42.1999 115 43.3999C115 44.8999 164.7 198.1 165.4 198.7C166.2 199.5 164.3 200.9 280.3 116.5L389 37.4999L385.2 35.1999C378.3 30.8999 361.6 22.6999 350.5 18.2999C331.8 10.7999 309 4.89993 287.5 1.99994C276.4 0.499939 245.5 -0.100067 234 0.999939Z"
                    fill="#4B0E1B"
                  />
                  <path
                    d="M348.5 103.9C312.2 130.3 282.5 152.2 282.6 152.6C282.7 153.1 332.6 189.7 393.6 234C466.3 286.9 504.7 314.2 505.2 313.5C505.6 312.8 506.8 307.2 507.9 300.9C523.6 212.4 492.1 123 424.1 63.4001C419.5 59.3001 415.5 56.0001 415.1 56.0001C414.8 56.0001 384.8 77.6001 348.5 103.9Z"
                    fill="#4B0E1B"
                  />
                  <path
                    d="M77.8 72.7C56.6 93.4 39.2 117.5 26.5 143.5C17.2 162.3 12.8 174.5 7.99996 193.5C-2.30004 234.5 -2.60004 274.5 7.09996 315.7L9.29996 325H91.7C136.9 325 174 324.7 174 324.2C174.1 322.1 89.4 63 88.6 63C88.1 63 83.2 67.4 77.8 72.7Z"
                    fill="#4B0E1B"
                  />
                  <path
                    d="M354.6 272.2C350.4 285 331.3 343.9 312.1 403C292.9 462.1 277.3 510.7 277.6 510.9C277.8 511.2 282.4 510.8 287.8 510.1C327.6 505 366.4 490.3 399.8 467.8C426.9 449.5 455.1 420.2 473.2 391.4C479.4 381.5 491.8 356.9 494.4 349.5L495.8 345.3L429.9 297.4C393.7 271 363.6 249.4 363.1 249.2C362.5 249 358.7 259.4 354.6 272.2Z"
                    fill="#4B0E1B"
                  />
                  <path
                    d="M20.9 357.4C23.1 363 34.3 384.2 39.4 392.3C53.2 414.2 75.3 439 95.5 455.3C135.5 487.6 191.4 509.5 238.4 511.2L245.3 511.5L270.6 433.5C284.6 390.6 296 355.4 296 355.2C296 355.1 233.9 355 158 355H20L20.9 357.4Z"
                    fill="#4B0E1B"
                  />
                </svg>
              </div>
            </div>
            <input
              className="w-full py-2 pl-4 mt-6 border-[1px] border-solid border-[rgba(0,0,0,0.07)] rounded-md outline-none font-normal text-sm text-black"
              placeholder="Ad Soyad"
              onChange={(e) => setGirlName(e.target.value)}
            />
            <input
              className="w-full py-2 pl-4 mt-6 border-[1px] border-solid border-[rgba(0,0,0,0.07)] rounded-md outline-none font-normal text-sm text-black"
              placeholder="Doğum Tarihi"
              onChange={(e) => setGirlBirthday(e.target.value)}
            />
          </div>
        </div>
        <input
          className="w-full py-2 pl-4 mt-6 border-[1px] border-solid border-[rgba(0,0,0,0.07)] rounded-md outline-none font-normal text-sm text-black"
          placeholder="İlişkiye Başlama Tarihiniz"
          onChange={(e) => setStartedDate(e.target.value)}
        />
        <p className="mt-8 text-sm text-center">
          Bilgilerin paylaşımında kişinin izni alınmıştır ve sorumluluk
          kullanıcıya aittir.
        </p>
        <button
          className="w-full max-w-md py-3 mt-16 bg-[#49111C] text-white rounded-lg border-none outline-none font-semibold text-md leading-5 cursor-pointer"
          onClick={handleSubmit}
        >
          Analiz Et (3 Jeton)
        </button>
      </div>
      <style jsx="true">{`
        .arrow-left {
          box-sizing: border-box;
          position: absolute;
          display: block;
          transform: scale(var(--ggs, 1));
          width: 22px;
          height: 22px;
          top: calc(50% - 11px);
        }
        .arrow-left::after,
        .arrow-left::before {
          content: "";
          display: block;
          box-sizing: border-box;
          position: absolute;
          left: 3px;
        }
        .arrow-left::after {
          width: 8px;
          height: 8px;
          border-bottom: 2px solid;
          border-left: 2px solid;
          transform: rotate(45deg);
          bottom: 7px;
        }
        .arrow-left::before {
          width: 16px;
          height: 2px;
          bottom: 10px;
          background: currentColor;
        }
        .succes-container {
          visibility: hidden;
          transition: visibility 0.1s ease-in-out;
        }
        .success-container-bg {
          backdrop-filter: blur(4px);
          opacity: 0;
          transition: opacity 0.1s ease-in-out;
        }
        .success-card {
          transform: translateY(-50%);
          bottom: 50%;
          transition: all 0.1s ease-in-out 0.1s;
          box-shadow: 0px 6px 14px -6px rgba(0, 0, 0, 0.12),
            0px 10px 32px -4px rgba(0, 0, 0, 0.1);
        }
        .succes-container.active {
          visibility: visible;
        }
        .succes-container.active .success-container-bg {
          opacity: 1;
        }
        .succes-container.active .success-card {
          transform: translateY(0%);
          bottom: 0%;
        }
      `}</style>
    </div>
  );
};

export default RelationshipAnalysis;
