import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../components/AuthProvider";
import NavigationBar from "../components/NavigationBar";
import { logout } from "../firebase/auth";

export const MyProfile = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  return (
    <div className="h-[100vh] flex flex-col items-center bg-white">
      <div className="w-full flex justify-center px-8">
        <div className="w-full max-w-md py-4 pl-4 text-lg font-semibold text-center">
          Profilim
        </div>
      </div>
      <div className="w-full flex justify-center bg-[#FAFAFA] h-full">
        <div className="w-full max-w-md flex flex-col items-center">
          <div className="w-full px-2 mt-6">
            <div className="flex items-center justify-between w-full bg-[#F5F5F5] p-4 rounded-xl">
              <div className="flex flex-col px-2">
                <span className="text-base font-semibold">
                  {currentUser.coin} Jeton
                </span>
                <span className="text-base font-medium text-[#717171] tracking-[-0.02em]">
                  Fal bakmak için jeton al!
                </span>
              </div>
              <Link
                to={"/magaza"}
                className="w-full max-w-[6rem] py-3 bg-[#49111C] text-white rounded-lg border-none outline-none font-semibold text-md leading-5 text-center"
              >
                Satın Al
              </Link>
            </div>
          </div>
          <div className="mx-4 self-start pt-8 pb-4 text-base font-semibold text-[#717171]">
            Menü
          </div>
          <Link
            to={"/profili-tamamla"}
            className="w-full flex items-center justify-between px-4 py-6 bg-[#F5F5F5] border-b-[1px] border-solid border-[rgba(0,0,0,0.07)] cursor-pointer"
          >
            <span className="text-base font-semibold">Kişisel Bilgiler</span>
            <div className="border-solid border-black rotate-[-45deg] p-1 border-l-0 border-t-0 border-r-[3px] border-b-[3px]"></div>
          </Link>
          <Link
            to={"/kullanim-sartlari"}
            target="_blank"
            className="w-full flex items-center justify-between px-4 py-6 bg-[#F5F5F5] border-b-[1px] border-solid border-[rgba(0,0,0,0.07)] cursor-pointer"
          >
            <span className="text-base font-semibold">Kullanım Şartları</span>
            <div className="border-solid border-black rotate-[-45deg] p-1 border-l-0 border-t-0 border-r-[3px] border-b-[3px]"></div>
          </Link>
          <Link
            to={"/gizlilik-sozlesmesi"}
            target="_blank"
            className="w-full flex items-center justify-between px-4 py-6 bg-[#F5F5F5] border-b-[1px] border-solid border-[rgba(0,0,0,0.07)] cursor-pointer"
          >
            <span className="text-base font-semibold">Gizlilik Sözleşmesi</span>
            <div className="border-solid border-black rotate-[-45deg] p-1 border-l-0 border-t-0 border-r-[3px] border-b-[3px]"></div>
          </Link>
          <div
            onClick={() => {
              logout();
              navigate("/giris-yap");
            }}
            className="w-full flex items-center justify-between px-4 py-6 bg-[#F5F5F5] border-b-[1px] border-solid border-[rgba(0,0,0,0.07)] cursor-pointer"
          >
            <span className="text-base font-semibold text-red-900">
              Çıkış Yap
            </span>
            <div className="border-solid border-red-900 rotate-[-45deg] p-1 border-l-0 border-t-0 border-r-[3px] border-b-[3px]"></div>
          </div>
        </div>
      </div>
      <NavigationBar />
    </div>
  );
};

export default MyProfile;
