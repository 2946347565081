import { useCallback, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import googleIcon from "../assets/images/google.webp";
import loginBg from "../assets/images/login-bg.png";
import logo from "../assets/images/logo-bifallasana.svg";
import { AuthContext } from "../components/AuthProvider";
import { logout, signInWithEmail, signInWithGoogle } from "../firebase/auth";
import { getUserData } from "../firebase/db";
import { auth } from "../firebase/index";

const LoginView = () => {
  const navigate = useNavigate();
  const { setCurrentUser } = useContext(AuthContext);
  const handleSignInWithEmail = useCallback(async (event) => {
    event.preventDefault();
    const { email, password } = event.target.form.elements;
    const [, error] = await signInWithEmail(email.value, password.value);
    if (error) {
      console.error(error);
    }
  }, []);

  const handleSignInWithGoogle = useCallback(async (event) => {
    event.preventDefault();
    const [, error] = await signInWithGoogle();
    if (error) {
      console.error(error);
    }
  }, []);

  auth.onAuthStateChanged(async (user) => {
    if (user) {
      const [userData, error] = await getUserData(user.uid);
      if (error) {
        setCurrentUser(null);
        console.error(error);
      } else {
        setCurrentUser({ ...userData, uid: user.uid });
        navigate("/profili-tamamla");
      }
    }
  });

  logout();

  return (
    <div className="relative bg-[#370954] min-h-[100vh] flex justify-center pt-8 px-2">
      <div
        style={{ backgroundImage: `url(${loginBg})` }}
        className="absolute top-0 left-0 right-0 bottom-0 w-full h-full blur-xl bg-cover"
      ></div>
      <form className="flex flex-col items-center max-w-md z-10 pb-4">
        <div className="w-[150px]">
          <img src={logo} alt="logo" />
        </div>
        <input
          className="w-full max-w-md mt-7 py-3 pl-4 bg-[#ffffff1a] rounded-xl border-[1px] border-solid border-[#4D375A] outline-none font-normal text-sm text-white"
          id="email"
          name="email"
          type="text"
          placeholder="E-posta adresini gir"
        />
        <input
          className="w-full max-w-md mt-4 py-3 pl-4 bg-[#ffffff1a] rounded-xl border-[1px] border-solid border-[#4D375A] outline-none font-normal text-sm text-white"
          id="password"
          name="password"
          type="password"
          placeholder="Şifreni gir"
        />
        <button
          type="button"
          className="w-full max-w-md py-3 mt-8 bg-[#49111C] text-white rounded-lg border-none outline-none font-semibold text-md leading-5 cursor-pointer"
          onClick={handleSignInWithEmail}
        >
          Giriş yap
        </button>
        <Link
          to={"/parolami-unuttum"}
          className="mt-6 font-normal text-xs text-white opacity-[0.7] cursor-pointer underline"
        >
          Parolamı Unuttum
        </Link>
        <div
          className="bg-[#ffffff1a] px-12 py-3 mt-8 rounded-xl cursor-pointer cursor-pointer"
          onClick={handleSignInWithGoogle}
        >
          <img className=" max-w-[15px]" src={googleIcon} alt="" />
        </div>
        <div className="mt-6 text-center font-normal text-xs text-white opacity-[0.7]">
          Giriş yaparak
          <Link
            to={"/kullanim-sartlari"}
            target="_blank"
            className="cursor-pointer underline px-1"
          >
            Kullanım Şartları
          </Link>
          ve
          <Link
            to={"/gizlilik-sozlesmesi"}
            target="_blank"
            className="cursor-pointer underline px-1"
          >
            Gizlilik Sözleşmesini
          </Link>
          kabul etmiş sayılırım.
        </div>
        <div className="w-full flex items-center mt-6">
          <div className="h-[1px] w-full bg-white opacity-[0.7]"></div>
          <span className="font-normal text-xs text-white opacity-[0.7] px-4">
            VEYA
          </span>
          <div className="h-[1px] w-full bg-white opacity-[0.7]"></div>
        </div>
        <Link
          to={"/eposta-ile-kayit-ol"}
          className="w-full max-w-md py-3 mt-8 bg-[#391E54] text-white rounded-lg border-none outline-none font-semibold text-md leading-5 text-center"
        >
          E-posta ile kayıt ol
        </Link>
      </form>
    </div>
  );
};

export default LoginView;
