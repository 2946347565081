import { useContext, useState } from "react";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../components/AuthProvider";
import FortuneOptions from "../components/FortuneOptions";
import SendCoffeeFortune from "../components/SendCoffeeFortune";
import { sendCoffeeRequest } from "../firebase/db";

export const CoffeeFortune = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [fortuneOption, setFortuneOption] = useState(null);
  const alert = useAlert();

  const backToFortuneOptions = () => {
    setFortuneOption(null);
  };

  const handleSubmit = async (photos, questions) => {
    const [isSuccess] = await sendCoffeeRequest(
      currentUser.uid,
      fortuneOption,
      photos,
      questions
    );

    if (isSuccess) {
      alert.success("Fal başarıyla gönderildi!", {
        timeout: 2000,
      });
      setTimeout(() => {
        navigate("/fallarim");
      }, 2000);
    } else {
      alert.error("Fal gönderilemedi!", {
        timeout: 2000,
      });
    }
  };

  return (
    <>
      {fortuneOption ? (
        <SendCoffeeFortune
          back={backToFortuneOptions}
          handleSubmit={handleSubmit}
          fortuneOption={fortuneOption}
        />
      ) : (
        <FortuneOptions selectFortuneOption={setFortuneOption} />
      )}
    </>
  );
};

export default CoffeeFortune;
