import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PaytrIframe = () => {
  const location = useLocation();
  const token = location.state.iframeToken;

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://www.paytr.com/js/iframeResizer.min.js";
    script.async = true;
    script.onload = () => {
      window.iFrameResize({}, "#paytriframe");
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (window.iFrameResize) {
    window.iFrameResize({}, "#paytriframe");
  }

  return (
    <>
      {token !== "" ? (
        <iframe
          src={`https://www.paytr.com/odeme/guvenli/${token}`}
          title="paytr"
          id="paytriframe"
          frameBorder="0"
          scrolling="no"
          style={{ width: "100%" }}
        ></iframe>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default PaytrIframe;
