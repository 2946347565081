import React, { useEffect, useState } from "react";
import { getUserData } from "../firebase/db";
import { auth } from "../firebase/index";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const [userData, error] = await getUserData(user.uid);
        if (error) {
          setCurrentUser(null);
          console.error(error);
        } else {
          setCurrentUser({ ...userData, uid: user.uid });
        }
      }
      setPending(false);
    });
  }, []);

  if (pending) {
    return <>Loading...</>;
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        setCurrentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
