import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-touch-drag-slider";
import fast from "../assets/images/fast.png";
import standard from "../assets/images/standard.png";
import super_fast from "../assets/images/super_fast.png";
import video from "../assets/images/video.png";
import voice from "../assets/images/voice.png";
import { getTarotOptions, getTarotPlans } from "../firebase/db";

export const TarotOptions = ({
  selectFortuneOption,
  selectFortunePlan,
  next,
}) => {
  const navigate = useNavigate();
  const images = { standard, fast, super_fast, voice, video };
  const [tarotOptions, setTarotOptions] = useState([]);
  const [tarotPlans, setTarotPlans] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);

  useEffect(() => {
    const getTarotOptionsData = async () => {
      const [data, error] = await getTarotOptions();
      if (error) {
        console.error(error);
      } else {
        setTarotOptions(data);
      }
    };

    const getTarotPlansData = async () => {
      const [data, error] = await getTarotPlans();
      if (error) {
        console.error(error);
      } else {
        setTarotPlans(data);
      }
    };

    getTarotOptionsData();
    getTarotPlansData();
  }, []);

  return (
    <div className="min-h-[100vh] flex flex-col items-center bg-[#FAFAFA]">
      <div className="w-full flex items-center justify-center border-b-[1px] border-solid border-[rgba(0,0,0,0.07)] px-2">
        <div className="w-full relative max-w-md py-4 text-center text-lg font-semibold">
          <div className="arrow-left left-4 cursor-pointer" onClick={() => navigate(-1)}></div>
          Tarot Opsiyonları
        </div>
      </div>
      <div className="flex flex-col items-center max-w-md bg-[#FAFAFA] w-full px-2 pb-4">
        <div className="text-[#969696] text-lg font-semibold leading-5 mt-4">
          Plan seç
        </div>
        <Slider activeIndex={0} threshHold={100} transition={0.5}>
          {tarotPlans.map((plan, index) => (
            <div
              className={
                "max-w-[20rem] flex flex-col items-center w-full bg-[#F5F5F5] mt-4 rounded-lg slider-item cursor-pointer" +
                (selectedPlan && selectedPlan.pick === plan.pick
                  ? " outline outline-2 outline-amber-200"
                  : "")
              }
              key={plan.pick}
              onClick={() => {
                setSelectedPlan(plan);
                selectFortunePlan(plan);
              }}
            >
              <img className="w-[40px]" src={standard} alt="" />
              <span className="text-lg font-semibold mt-2">{plan.title}</span>
              <button className="w-full max-w-[6rem] py-3 mt-2 bg-[#49111C] text-white rounded-lg border-none outline-none font-semibold text-md leading-5">
                {plan.coin} Jeton
              </button>
            </div>
          ))}
        </Slider>
        <div className="text-[#969696] text-lg font-semibold leading-5 mt-4">
          Opsiyon seç
        </div>
        {tarotOptions.map((coffeeOption) => {
          return (
            <div
              key={coffeeOption.title}
              className={
                "flex flex-col items-start w-full bg-[#F5F5F5] mt-4 rounded-lg p-4 cursor-pointer" +
                (selectedOption && selectedOption.title === coffeeOption.title
                  ? " outline outline-2 outline-amber-200"
                  : "")
              }
              onClick={() => {
                setSelectedOption(coffeeOption);
                selectFortuneOption(coffeeOption);
              }}
            >
              <img
                className="w-[40px]"
                src={
                  images[
                    coffeeOption.icon.replace("assets/", "").replace(".png", "")
                  ]
                }
                alt=""
              />
              <div className="text-lg font-semibold mt-2">
                {coffeeOption.title}
              </div>
              <div className="text-left mt-2 text-[#B1B1B1] text-sm font-semibold">
                {coffeeOption.subtitle}
              </div>
              <button className="w-full max-w-[6rem] py-3 mt-2 bg-[#49111C] text-white rounded-lg border-none outline-none font-semibold text-md leading-5">
                {coffeeOption.coin} Jeton
              </button>
            </div>
          );
        })}
      </div>
      <div className="fixed bottom-0 w-full bg-white shadow-[0px_-2px_6px_#f3f3f3]">
        <div className=" max-w-md flex justify-between px-8 py-4 mx-auto">
          <button
            disabled={!selectedPlan || !selectedOption}
            className="w-full py-3 mt-2 disabled:bg-[#9E9E9E] bg-orange-900 text-white rounded-lg border-none outline-none font-semibold text-md leading-5 cursor-pointer"
            onClick={next}
          >
            Devam Et{" "}
            {selectedPlan && selectedOption
              ? "(" + (selectedPlan.coin + selectedOption.coin) + " Jeton)"
              : ""}
          </button>
        </div>
      </div>
      <style jsx="true">{`
        .arrow-left {
          box-sizing: border-box;
          position: absolute;
          display: block;
          transform: scale(var(--ggs, 1));
          width: 22px;
          height: 22px;
          top: calc(50% - 11px);
        }
        .arrow-left::after,
        .arrow-left::before {
          content: "";
          display: block;
          box-sizing: border-box;
          position: absolute;
          left: 3px;
        }
        .arrow-left::after {
          width: 8px;
          height: 8px;
          border-bottom: 2px solid;
          border-left: 2px solid;
          transform: rotate(45deg);
          bottom: 7px;
        }
        .arrow-left::before {
          width: 16px;
          height: 2px;
          bottom: 10px;
          background: currentColor;
        }
        .fMMrtD div {
          height: auto;
          padding: 0;
        }
        .kyxaOE div {
          height: auto;
        }
        .slider-item {
          padding: 1rem !important;
          height: auto !important;
        }
        .slider-title {
          height: auto !important;
        }
        .slide-inner {
          height: fit-content !important;
        }
        .SliderStyles {
          height: 208px !important;
        }
        .succes-container {
          visibility: hidden;
          transition: visibility 0.1s ease-in-out;
        }
        .success-container-bg {
          backdrop-filter: blur(4px);
          opacity: 0;
          transition: opacity 0.1s ease-in-out;
        }
        .success-card {
          transform: translateY(-50%);
          bottom: 50%;
          transition: all 0.1s ease-in-out 0.1s;
          box-shadow: 0px 6px 14px -6px rgba(0, 0, 0, 0.12),
            0px 10px 32px -4px rgba(0, 0, 0, 0.1);
        }
        .succes-container.active {
          visibility: visible;
        }
        .succes-container.active .success-container-bg {
          opacity: 1;
        }
        .succes-container.active .success-card {
          transform: translateY(0%);
          bottom: 0%;
        }
      `}</style>
    </div>
  );
};

export default TarotOptions;
