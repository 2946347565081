import React from "react";
import { Link } from "react-router-dom";
import coffeeCardBg from "../assets/images/coffee.png";
import dreamCardBg from "../assets/images/dream.webp";
import tarotCardBg from "../assets/images/tarot.png";
import valentineCardBg from "../assets/images/valentine.webp";
import NavigationBar from "../components/NavigationBar";

export const MainView = () => {
  return (
    <div className="min-h-[100vh] flex flex-col items-center">
      <div className="w-full flex justify-center border-b-[1px] border-solid border-[rgba(0,0,0,0.07)] px-2">
        <div className="w-full max-w-md py-4 pl-4 text-lg font-semibold text-center">
          Anasayfa
        </div>
      </div>
      <div className="w-full flex justify-center pb-24">
        <div className="flex flex-wrap max-w-md w-full px-2">
          <Link
            to="/kahve-fali"
            style={{ backgroundImage: `url(${coffeeCardBg})` }}
            className="relative px-2 mx-auto mt-6 w-[45%] h-[13.125rem] pt-6 bg-cover rounded-2xl bg-center flex justify-center"
          >
            <span className="text-white text-lg font-semibold">Kahve Falı</span>
          </Link>
          <Link
            to={"/tarot-fali"}
            style={{ backgroundImage: `url(${tarotCardBg})` }}
            className="relative px-2 mx-auto mt-6 w-[45%] h-[13.125rem] pt-6 bg-cover rounded-2xl bg-center flex justify-center"
          >
            <span className="text-white text-lg font-semibold">Tarot Falı</span>
          </Link>
          <Link
            to={"/iliski-analizi"}
            style={{ backgroundImage: `url(${valentineCardBg})` }}
            className="relative px-2 mx-auto mt-6 w-[45%] h-[13.125rem] pt-6 bg-cover rounded-2xl bg-center flex justify-center"
          >
            <span className="text-white text-lg font-semibold">
              İlişki Analizi
            </span>
          </Link>
          <Link
            to={"/ruya-yorumu"}
            style={{ backgroundImage: `url(${dreamCardBg})` }}
            className="relative px-2 mx-auto mt-6 w-[45%] h-[13.125rem] pt-6 bg-cover rounded-2xl bg-center flex justify-center"
          >
            <span className="text-white text-lg font-semibold">
              Rüya Yorumu
            </span>
          </Link>
        </div>
      </div>
      <NavigationBar />
    </div>
  );
};

export default MainView;
