export const TermsOfUse = () => {
  return (
    <div className="h-[100vh] flex flex-col items-center bg-white">
      <div className="w-full flex justify-center px-8">
        <div className="w-full max-w-md py-4 pl-4 text-lg font-semibold text-center">
          Kullanım Şartları
        </div>
      </div>
      <div className="w-full flex justify-center bg-[#FAFAFA] h-full">
        <div className="w-full max-w-md flex flex-col items-center p-4">
          bifallasana Üyelik Sözleşmesi bifallasana Kullanım Koşulları ve Üyelik
          Sözleşmesi İş bu Kullanım Şartları, bifallasana uygulaması tüm özel ve
          tüzel kişi ve kurumlar (bundan böyle KULLANICI olarak anılacaktır)
          arasındaki kullanım şartlarını içermektedir. KULLANICIlar,
          bifallasana'da sunulan hizmetlerden (belirli bir bedel ödeyerek ya da
          bedelsiz olarak) yararlanarak veya herhangi bir şekilde site veya
          uygulamaya erişim sağlayarak 18 yaşından büyük olduklarını veya reşit
          olmayıp ebeveynlerinin ya da kendilerine bakmakla yükümlü bir kişinin
          yasal onayına sahip olduklarını; iş bu Kullanım Şartları'nın tamamını
          okuduklarını ve içeriğini tamamıyla anladıklarını ve tüm maddelerini
          kayıtsız şartsız kabul ederek onayladıklarını peşinen taahhüt ederler.
          Bu KULLANICIlar bifallasana tarafından iş bu Site Kullanım Koşulları
          hükümlerinde yapılan her değişikliği önceden kabul etmiş
          sayılmaktadır. bifallasana’da KULLANICI olmak için uygulamanın
          indirilmesi ve kullanıcı bilgileri formuna bilgi girişi yaparken
          istenilen hususların eksiksiz ve açık doldurulması zorunludur.
          KULLANICIlar bu formla aktarılan bilgilerin doğruluğunu kayıtsız
          şartsız taahhüt eder. bifallasana tüm KULLANICIların kişilik haklarına
          ve gizlilik haklarına saygı duymaktadır. Maddelerde yazılı sebepler
          dışında ve yargı mercileri istemi haricinde, her ne sebeple olursa
          olsun kişisel bilgileri, hiçbir kurum veya kuruluşa vermeyeceğini
          kabul ve taahhüt eder. bifallasana, veri tabanına yapılabilecek
          saldırılar sonucu KULLANICI bilgilerinin kötü amaçlı kişilerin eline
          geçmesi ve bunların kötü niyetli kullanılması halinde doğabilecek
          sonuçlardan dolayı sorumluluk kabul etmez. Her türlü kullanım ve
          yazılımlarda telif hakları ve eser haklarına tecavüz edilmeyecektir.
          KULLANICIların eklediği içeriklerden dolayı oluşabilecek her türlü
          telif hakkı ve insan hakları sorumluluğu kullanıcılara aittir. Bu
          sebeple bifallasana’ya rücu kesinlikle mümkün değildir. bifallasana ve
          KULLANICI, hukuken tamamen bağımsız taraflardır. Bu iki bağımsız taraf
          arasında herhangi bir iş ortaklığı ve/veya işçi/işveren ilişkisi
          bulunmamaktadır. Yorum isterken veya yaparken verilen bilgilerde
          kesinlikle yasalara aykırı ve genel ahlak prensibine uygun olmayan
          kişisel kimliklere saldırı mahiyetinde yazı yazılamaz. Yasalara göre,
          suç sayılan bir eylem aracı olarak bifallasana girdileri kullanıldığı
          zaman yasal mercilerden bir talep vuku bulursa, kullanıcılar
          hakkındaki bilgiler gerekli yerlere istem doğrultusunda verilecektir.
          bifallasana uygulamasında bulunan tüm içerikler Fikir ve Sanat
          Eserleri Kanunu'na tabidir ve içeriğine ilişkin her türlü ses,
          görüntü, yazı, belge ve her türlü fikri ve sınai haklar ile tüm telif
          hakları ve diğer fikri ve sinai mülkiyet hakları GEB TEKNOLOJİ YAZILIM
          SANAYİ VE TİCARET ANONİM ŞİRKETİ’ne aittir. Bu uygulamanın yapısı ve
          içeriği, sitede kullanılan her türlü görsel malzeme Fikir ve Sanat
          Eserleri Kanunu ve Türk Ceza Kanunu kapsamında korunmaktadır.
          bifallasana uygulamasında bulunan hiçbir yazı; önceden izin alınmadan
          ve kaynak gösterilmeden, kod ve yazılım da dahil olmak üzere,
          değiştirilemez, kopyalanamaz, çoğaltılamaz, başka bir lisana
          çevrilemez, yeniden yayımlanamaz, başka bir bilgisayara yüklenemez,
          postalanamaz, iletilemez, sunulamaz ya da dağıtılamaz, bütünü veya bir
          kısmı kaynak gösterilmeden başka bir web sitesinde veya uygulamada
          izinsiz olarak kullanılamaz. KULLANICI site veya uygulama üzerindeki
          interaktif alanlar yoluyla suç teşkil edecek, yasal açıdan takip
          gerektirecek; yerel ve ulusal yasalara ya da uluslararası anlaşmalara
          ters düşecek bir durum yaratan ya da böyle durumları teşvik eden,
          hiçbir tür yasadışı, tehditkar, rahatsız edici, hakaret, küfür içeren,
          küçük düşürücü, kaba, pornografik, rahatsız edici ya da ahlaka aykırı
          bilgi postalayamaz ya da iletemez. KULLANICI, bifallasana sitesinde ve
          uygulamasında yayımladığı, paylaştığı içeriklerden 26/9/2004 tarihli
          ve 5237 sayılı Türk Ceza kanununda yer alan ve aşağıda sıralanmış
          hükümler çerçevesinde sorumludur: İntihara Yönlendirme: Başkasını
          intihara azmettirmek, teşvik etmek, başkasının intihar kararını
          kuvvetlendirmek suçtur ve kabul edilemez. Çocukların Cinsel İstismarı:
          On beş yaşını tamamlamamış veya tamamlamış olmakla birlikte fiilin
          hukuki anlam ve sonuçlarını algılama yeteneği gelişmemiş olan
          çocuklara karşı gerçekleştirilen her türlü cinsel davranış; diğer
          çocuklara karşı sadece cebir, tehdit, hile veya iradeyi etkileyen
          başka bir nedene dayalı olarak gerçekleştirilen cinsel davranışlar
          suçtur ve kabul edilemez. Uyuşturucu veya Uyarıcı Madde Kullanılmasını
          Kolaylaştırma: Uyuşturucu veya uyarıcı madde kullanılmasını
          kolaylaştırmak amacıyla özel yer, donanım veya malzeme sağlayan,
          kullanma yöntemleri konusunda başkalarına bilgi vermek suçtur ve kabul
          edilemez. Sağlık için Tehlikeli Madde Temini: Sağlık için tehlike
          oluşturabilecek maddeleri çocuklara, akıl hastalarına veya uçucu madde
          kullananlara vermek suçtur ve kabul edilemez. Müstehcenlik: Müstehcen
          görüntülerin, yazı veya sözlerin, içeriğin yayımlanması, paylaşılması
          suçtur ve kabul edilemez. Fuhuş: Bir çocuğu, bir kimseyi fuhuşa teşvik
          etmek, bunun yolunu kolaylaştırmak, aracılık etmek suçtur ve kabul
          edilemez. Kumar Oynanması İçin Yer ve İmkân Sağlama: Çocukların,
          kişilerin kumar oynaması için yer ve imkân sağlamak suçtur ve kabul
          edilemez. Atatürk Aleyhine İşlenen Suçlar: Atatürk'ün hatırasına
          alenen hakaret etmek veya sövmek suçtur ve kabul edilemez. bifallasana
          üzerinden kurulan arkadaşlıklarda ve ilişkilerde sorumluluk tamamen
          KULLANICIlara aittir. bifallasana tüm ziyaretçilerine, kişisel
          bilgilerini açıklama konusunda maksimum dikkat göstermesini ve
          paylaşmamasını önerir. bifallasana uygulamasında geçmişe yönelik
          hediye krediler toplu olarak VERİLMEZ. Hediye krediler zamanında
          alınmalıdır. Zaman aşımına uğrayan hediye kredi VERİLMEZ. bifallasana
          daki yorumlar yorumcunun veya yorumcuların hayal gücü ile üretilmiş
          olup gerçeği yansıtmaz, doğruluk içermez ve gelecekten haber vermez.
          bifallasana’daki amaç sohbet ve eğlence amaçlı olarak yorumcu veya
          yorumcuların kişisel yorumlarıyla oluşturdukları içerikleden
          oluşmaktadır ve bu yorumlar engelliler ve 18 yaşından küçükler için
          sakıncalı olabilir. bifallasana'da telif hakkı ile korunan içeriklerin
          paylaşılması KESİNLİKLE yasaktır. Paylaşım Bölümü 18 yaşından küçükler
          ve engelliler için sakıncalı olabilir Bifallasana daki sohbet ve
          eğlenci amaçlı olarak yorumcu veya yorumcuların kişisel yorumlarından
          doğabilecek sonuçlardan bifallasana kesinlikle sorumlu değildir.
          Bununla birlikte bifallasana’daki tüm kullanıcıların görebileceği tüm
          içerikler bir onay sürecinden sonra yayınlanmaktadır. Kullanıcılar
          ticari amaç güden hiçbir bilgiyi paylaşamaz, reklam veremez, telefon
          numarası, banka hesap bilgisi veya email adresi veremezler bifallasana
          Fincan fotoğrafı gönderme bölümünde fincan fotoğrafının haricinde
          başka fotoğraf gönderen kullanıcıların üyeliklerini iptal etme hakkını
          saklı tutar. KULLANICI verilerinin bifallasana’nın ihmali görülmeden
          yetkisiz kişilerce okunmasından (KULLANICInın bilgilerini başka
          kişilerle paylaşması, siteden ayrılırken çıkış yapmaması, uygulamanın
          açık olduğu telefonun umuma açık alanda unutulması veya çalınması vb.
          durumlardan) dolayı gelebilecek zararlardan bifallasana sorumlu
          değildir. bifallasana, hiçbir KULLANICInın davranışından sorumlu
          değildir. Hiçbir koşulda sitenin ve uygulamanın kullanımı, içeriği,
          çevrimiçi ya da çevrimdışı konumda olsunlar KULLANICIlar arasındaki
          iletişim ve etkileşimden doğan sonuçlardan kayıp, hasar, zarar, ölüm,
          vs. dahi olsa sorumlu değildir. bifallasana uygulamasında fallara
          yapılan yorumlardan falcılar sorumludur. Yazılarda insani değerleri
          küçük düşürücü, yasalara ve devlete karşı herhangi bir propaganda
          yapmak kesinlikle yasaktır. bifallasana hiçbir sebep beyan etmeden
          istediği KULLANICIlara yorum talep etmek için kullandıkları yorum
          kredilerini veya yorum ücretini iade ederek hizmet vermeyi reddetme
          hak ve yetkisine sahiptir. bifallasana, üyelik gerektirmeyen
          servislerini zaman içinde üyelik gerektiren bir duruma dönüştürebilir,
          ilave servisler açabilir, servislerini kısmen veya tamamen
          değiştirebilir, süreli ve süresiz sona erdirebilir, ücretli hale
          getirebilir. Kullanıcı veya kullanıcılar site ve site üzerindeki
          interaktif alanlar yoluyla suç teşkil edecek, yasal açıdan takip
          gerektirecek; yerel ve ulusal yasalara ya da uluslararası anlaşmalara
          ters düşecek bir durum yaratan ya da böyle durumları teşvik eden,
          hiçbir tür yasadışı, tehditkâr, rahatsız edici, hakaret, küfür içeren,
          küçük düşürücü, kaba, pornografik, rahatsız edici ya da ahlaka aykırı
          bilgi postalayamaz ya da iletemez. bifallasana kesinlikle siyasi
          amaçlı veya saldırı amaçlı kullanılamaz. bifallasana kullanıcıların
          kişilik haklarına ve gizliliklerine saygılıdır. Koşullardaki
          maddelerde, kullanıcıların kendi isteği veya yetkili mercilerin talebi
          haricinde kullanıcıların bilgilerini gizili tutacağını taahhüt eder
          Kullanıcılar arasında diğer kullanıcılara kötü
          içerikli,yanıltıcı,aldatıcı,hakaret içeren mesajlar, paylaşımlar veya
          yorumlar yazmak yasaktır. Böyle bir durumda kullanıcıların üyeliğini
          iptal etme hakkını bifallasana saklı tutar. bifallasana sitesinde
          kesinlikle büyü, hurafe vb. ile ilgili içerik ve tavsiye, öneri gibi
          yazılar,resimler,öneriler bulunmamaktadır,bulunamaz ve
          bulundurulmasına izin verilmez bifallasana'yı kullanan tüm
          kullanıcılar bu koşulları kabul ettiğini taahhüt eder. bifallasana,
          KULLANICIların gizliliğini ihlal etmeden, hizmet kalitesini yükseltmek
          amacıyla uygulama veya web sitesi üzerinde anonim kullanım bilgisi ile
          yine anonim hale getirerek cihaz, yer ve şebeke kullanım verileri gibi
          bilgilerinizi toplayabilir. bifallasana, bu anonim bilgileri
          istatistiki ve performans değerlendirmelerinde, yıllık rapor ve
          benzeri raporlarda, bifallasana ve iş ortaklarının pazarlama
          kampanyaları ile mobil operatörlerin veri hizmetlerinin
          pazarlanmasında kullanabilir ve bunların gerçekleştirilmesi için
          gerekli sure boyunca saklayabilir, işleyebilir ve iş ortaklarına
          iletebilir. Bu bilgilerinizi kullanımımız, bu sözleşmenin Gizlilik
          Politikasına tabi olacaktır. Lütfen Gizlilik Politikasını dikkatlice
          okuyunuz. bifallasana hiçbir sebep beyan etmeden istediği
          KULLANICIların üyeliklerini iptal etme, sunduğu hizmetin şeklini
          değiştirme ve/veya iptal etme hak ve yetkisine sahiptir.
          bifallasana’nın iptal ettiği veya üyelikleri sözleşme maddeleri
          gereğince iptal edilen KULLANICIların üyelik bilgileri bifallasana
          tarafından saklanacaktır. bifallasana, Kullanım Şartları'nı tek
          taraflı olarak değiştirme ya da tadil etme hakkına sahiptir. Size en
          iyi hizmeti vermemiz için bu kurallara uymanızı rica ederiz. Bu
          kuralları kabul ettiğiniz bilmenizi isteriz. Bilmek için bifallasana!
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;
