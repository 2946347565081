import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../components/AuthProvider";
import { getUserData, sendTarotRequest } from "../firebase/db";
import SelectCard from "./SelectCard";
import TarotOptions from "./TarotOptions";
import { useAlert } from "react-alert";

export const TarotFortune = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [fortuneOption, setFortuneOption] = useState(null);
  const [fortunePlan, setFortunePlan] = useState(null);
  const [isStepSelectCard, setIsStepSelectCard] = useState(false);
  const alert = useAlert();

  const backToFortuneOptions = () => {
    setIsStepSelectCard(false);
  };

  const next = async () => {
    const [user, error] = await getUserData(currentUser.uid);
    const price = fortuneOption.coin + fortunePlan.coin;
    if (!error && user.coin >= price) {
      setIsStepSelectCard(true);
    } else {
      alert.error("Mağaza sayfasından jeton almalısın!", {
        timeout: 2000,
      });
    }
  };

  const handleSubmit = async (selectedCards) => {
    const [isSuccess] = await sendTarotRequest(
      currentUser.uid,
      fortuneOption,
      fortunePlan,
      selectedCards
    );
    if (isSuccess) {
      alert.success("Fal başarıyla gönderildi!", {
        timeout: 2000,
      });
      setTimeout(() => {
        navigate("/fallarim");
      }, 2000);
    } else {
      // TODO:
      alert.error("Fal gönderilemedi!", {
        timeout: 2000,
      });
    }
  };

  return (
    <>
      {isStepSelectCard ? (
        <SelectCard
          back={backToFortuneOptions}
          handleSubmit={handleSubmit}
          pickCount={fortunePlan.pick}
        />
      ) : (
        <TarotOptions
          selectFortuneOption={setFortuneOption}
          selectFortunePlan={setFortunePlan}
          next={next}
        />
      )}
    </>
  );
};

export default TarotFortune;
