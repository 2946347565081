import React from "react";
import ReactPlayer from "react-player";
import logo from "../assets/images/logo.svg";
import moment from "moment";
moment().locale("tr");

export const FortuneAnswer = ({ fortune, back }) => {
  return (
    <div className="h-[100vh] flex flex-col items-center bg-[#FAFAFA]">
      <div className="w-full flex items-center justify-center border-b-[1px] border-solid border-[rgba(0,0,0,0.07)] px-2">
        <div className="w-full relative max-w-md py-4 text-center text-lg font-semibold">
          <div
            className="arrow-left left-4 cursor-pointer"
            onClick={back}
          ></div>
          {fortune.option.title}
        </div>
      </div>
      <div className="flex flex-col items-center max-w-md w-full h-full tracking-[-0.02em] bg-[#FAFAFA]">
        <div className="w-full border-b-[1px] border-solid border-[rgba(0,0,0,0.07)]">
          <div className="w-full py-2 flex items-center px-4">
            <div className="max-w-[2.5rem] mr-4">
              <img className="rounded-full" src={logo} alt="" />
            </div>
            <div className="flex flex-col">
              <span className="text-[#7E3C1F] text-base font-semibold text-left mr-4">
                {fortune.fortuneTeller.name} tarafından yorumlandı
              </span>
              <span className="text-[#A0A0A0] text-sm font-semibold">
                {moment(fortune.createdAt.toDate()).format("DD MMMM, HH:mm")}
              </span>
            </div>
          </div>
        </div>
        {fortune.comment.includes("http") ? (
          <div className="w-full h-full flex items-center">
            <ReactPlayer
              url={fortune.comment.replace("&", "&amp;")}
              controls="true"
              playsinline
            />
          </div>
        ) : (
          <div className="mt-6 text-[#202020] text-base font-medium text-center">
            {fortune.comment}
          </div>
        )}
      </div>
      <style jsx="true">{`
        .arrow-left {
          box-sizing: border-box;
          position: absolute;
          display: block;
          transform: scale(var(--ggs, 1));
          width: 22px;
          height: 22px;
          top: calc(50% - 11px);
        }
        .arrow-left::after,
        .arrow-left::before {
          content: "";
          display: block;
          box-sizing: border-box;
          position: absolute;
          left: 3px;
        }
        .arrow-left::after {
          width: 8px;
          height: 8px;
          border-bottom: 2px solid;
          border-left: 2px solid;
          transform: rotate(45deg);
          bottom: 7px;
        }
        .arrow-left::before {
          width: 16px;
          height: 2px;
          bottom: 10px;
          background: currentColor;
        }
        .radio-button input:checked ~ div {
          background-color: #87431e;
          color: #fff;
        }
        .active .cup-icon path {
          fill: #ceb49c;
        }
        .active .cup-text {
          color: #5f2d3b !important;
        }
        .active .check-icon path {
          fill: #874220;
        }
        .rhap_container {
          background: transparent;
          box-shadow: none;
        }
        .rhap_additional-controls {
          visibility: hidden;
        }
      `}</style>
    </div>
  );
};

export default FortuneAnswer;
