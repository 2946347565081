import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from ".";
import { generateReferCode } from "../utils";

export const getUserData = async (uid) => {
  try {
    const colRef = await collection(db, "users");
    const docRef = await doc(colRef, uid);
    const snapshot = await getDoc(docRef);
    if (!snapshot.exists()) {
      throw new Error("User not found");
    }
    return [snapshot.data(), null];
  } catch (error) {
    return [null, error];
  }
};

export const setUserData = async (uid, name, email, gender) => {
  try {
    const user = {
      name,
      email,
      gender,
      coin: 0,
      photo: null,
      referCode: generateReferCode(8),
      createdAt: serverTimestamp(),
      token: null,
    };
    const colRef = await collection(db, "users");
    const docRef = await doc(colRef, uid);
    await setDoc(docRef, user);
    return [true, null];
  } catch (error) {
    return [null, error];
  }
};

export const completeProfile = async (uid, name, gender, allowMail, photo) => {
  try {
    const colRef = await collection(db, "users");
    const docRef = await doc(colRef, uid);
    await updateDoc(docRef, { name, gender, allowMail, photo });
    return [true, null];
  } catch (error) {
    return [null, error];
  }
};

export const updateUserCoin = async (uid, coin) => {
  try {
    const colRef = await collection(db, "users");
    const docRef = await doc(colRef, uid);
    await updateDoc(docRef, { coin });
    return [true, null];
  } catch (error) {
    return [null, error];
  }
};

export const getCoffeeOptions = async () => {
  try {
    const coffeeOptions = [];
    const colRef = await collection(db, "coffeeOptions");
    const q = await query(colRef, orderBy("order"));
    const docRef = await getDocs(q);
    if (!docRef.docs) {
      throw new Error("Coffee options not found");
    }
    docRef.docs.forEach((doc) => {
      coffeeOptions.push(doc.data());
    });
    return [coffeeOptions, null];
  } catch (error) {
    return [null, error];
  }
};

export const getTarotOptions = async () => {
  try {
    const tarotOptions = [];
    const colRef = await collection(db, "tarotOptions");
    const q = await query(colRef, orderBy("order"));
    const docRef = await getDocs(q);
    if (!docRef.docs) {
      throw new Error("Tarot options not found");
    }
    docRef.docs.forEach((doc) => {
      tarotOptions.push(doc.data());
    });
    return [tarotOptions, null];
  } catch (error) {
    return [null, error];
  }
};

export const getTarotPlans = async () => {
  try {
    const tarotPlans = [];
    const colRef = await collection(db, "tarotPlans");
    const q = await query(colRef, orderBy("pick"));
    const docRef = await getDocs(q);
    if (!docRef.docs) {
      throw new Error("Tarot plans not found");
    }
    docRef.docs.forEach((doc) => {
      tarotPlans.push(doc.data());
    });
    return [tarotPlans, null];
  } catch (error) {
    return [null, error];
  }
};

export const getCards = async () => {
  try {
    const colRef = await collection(db, "constants");
    const docRef = await doc(colRef, "IvKqlBydqgxKlKa8x09p");
    const snapshot = await getDoc(docRef);

    if (!snapshot.exists()) {
      throw new Error("Cards not found");
    }

    const cards = snapshot.data().CARDS.map((c) => ({
      url: c,
      back: snapshot.data().CARD_BACK,
      selected: false,
    }));

    return [cards, null];
  } catch (error) {
    return [null, error];
  }
};

export const sendTarotRequest = async (userId, option, plan, selectedCards) => {
  try {
    const [user, error] = await getUserData(userId);

    if (!error) {
      let currentCoin = user.coin;
      currentCoin -= option.coin + plan.coin;

      if (currentCoin < 0) {
        throw new Error("Not enough coin");
      }
      user.coin = currentCoin;
      await updateUserCoin(userId, currentCoin);
      const tarotRequest = {
        option: option,
        plan: plan,
        cards: selectedCards,
        user: user,
        userId: userId,
        createdAt: serverTimestamp(),
        giveback: false,
        answered: false,
        comment: null,
        thumb: null,
        fortuneTeller: {},
      };
      const colRef = await collection(db, "tarotRequests");
      await addDoc(colRef, tarotRequest);
      return [true, null];
    } else {
      throw new Error(error);
    }
  } catch (error) {
    return [null, error];
  }
};

export const sendCoffeeRequest = async (userId, option, photos, questions) => {
  try {
    const [user, error] = await getUserData(userId);

    if (!error) {
      let currentCoin = user.coin;
      currentCoin -= option.coin;

      if (currentCoin < 0) {
        throw new Error("Not enough coin");
      }
      user.coin = currentCoin;
      await updateUserCoin(userId, currentCoin);
      const coffeeRequest = {
        option: option,
        photos: photos,
        questions,
        user: user,
        userId: userId,
        createdAt: serverTimestamp(),
        answered: false,
        giveback: false,
        comment: null,
        thumb: null,
        fortuneTeller: {},
      };
      const colRef = await collection(db, "coffeeRequests");
      await addDoc(colRef, coffeeRequest);
      return [true, null];
    } else {
      throw new Error(error);
    }
  } catch (error) {
    return [null, error];
  }
};

export const givebackCoffeeFortune = async (fortune) => {
  try {
    const colRef = await collection(db, "coffeeRequests");
    const docRef = await doc(colRef, fortune.id);
    await updateDoc(docRef, {
      giveback: true,
    });

    return [true, null];
  } catch (error) {
    return [null, error];
  }
};

export const givebackTarotFortune = async (fortune) => {
  try {
    const colRef = await collection(db, "tarotRequests");
    const docRef = await doc(colRef, fortune.id);
    await updateDoc(docRef, {
      giveback: true,
    });

    return [true, null];
  } catch (error) {
    return [null, error];
  }
};

export const getCoffeeRequests = async (uid) => {
  try {
    const requests = [];
    const colRef = await collection(db, "coffeeRequests");
    const q = await query(colRef, where("userId", "==", uid));
    const docRef = await getDocs(q);

    docRef.docs.forEach((doc) => {
      requests.push({ ...doc.data(), id: doc.id });
    });
    requests.sort((a, b) =>
      a.createdAt.toDate() > b.createdAt.toDate() ? -1 : 1
    );

    return [requests, null];
  } catch (error) {
    return [null, error];
  }
};

export const getTarotRequests = async (uid) => {
  try {
    const requests = [];
    const colRef = await collection(db, "tarotRequests");
    const q = await query(colRef, where("userId", "==", uid));
    const docRef = await getDocs(q);

    docRef.docs.forEach((doc) => {
      requests.push({ ...doc.data(), id: doc.id });
    });
    requests.sort((a, b) =>
      a.createdAt.toDate() > b.createdAt.toDate() ? -1 : 1
    );

    return [requests, null];
  } catch (error) {
    return [null, error];
  }
};

export const getLoveRequests = async (uid) => {
  try {
    const requests = [];
    const colRef = await collection(db, "loveRequests");
    const q = await query(colRef, where("userId", "==", uid));
    const docRef = await getDocs(q);

    docRef.docs.forEach((doc) => {
      requests.push({ ...doc.data(), id: doc.id });
    });
    requests.sort((a, b) =>
      a.createdAt.toDate() > b.createdAt.toDate() ? -1 : 1
    );

    return [requests, null];
  } catch (error) {
    return [null, error];
  }
};

export const getDreamRequests = async (uid) => {
  try {
    const requests = [];
    const colRef = await collection(db, "dreamRequests");
    const q = await query(colRef, where("userId", "==", uid));
    const docRef = await getDocs(q);

    docRef.docs.forEach((doc) => {
      requests.push({ ...doc.data(), id: doc.id });
    });
    requests.sort((a, b) =>
      a.createdAt.toDate() > b.createdAt.toDate() ? -1 : 1
    );

    return [requests, null];
  } catch (error) {
    return [null, error];
  }
};

export const sendDreamRequest = async (userId, dream) => {
  try {
    const [user, error] = await getUserData(userId);
    if (!error) {
      let currentCoin = user.coin;
      currentCoin -= 3;

      if (currentCoin < 0) {
        throw new Error("Not enough coin");
      }
      user.coin = currentCoin;
      await updateUserCoin(userId, currentCoin);
      const dreamRequest = {
        user: user,
        userId: userId,
        createdAt: serverTimestamp(),
        answered: false,
        comment: null,
        dream: dream,
        fortuneTeller: {},
      };

      const colRef = await collection(db, "dreamRequests");
      await addDoc(colRef, dreamRequest);
      return [true, null];
    } else {
      throw new Error(error);
    }
  } catch (e) {
    return [null, e];
  }
};

export const sendLoveRequest = async (
  userId,
  girlName,
  boyName,
  girlPhoto,
  boyPhoto,
  girlBirthday,
  boyBirthday,
  startedDate
) => {
  try {
    const [user, error] = await getUserData(userId);

    if (!error) {
      let currentCoin = user.coin;
      currentCoin -= 3;

      if (currentCoin < 0) {
        throw new Error("Not enough coin");
      }
      user.coin = currentCoin;
      await updateUserCoin(userId, currentCoin);
      const loveRequest = {
        user,
        userId: userId,
        boyName: boyName,
        girlName: girlName,
        girlPhoto: girlPhoto,
        boyPhoto: boyPhoto,
        girlBirthday: girlBirthday,
        boyBirthday: boyBirthday,
        createdAt: serverTimestamp(),
        answered: false,
        comment: null,
        fortuneTeller: {},
        startedDate,
      };
      const colRef = await collection(db, "loveRequests");
      await addDoc(colRef, loveRequest);

      return [true, null];
    } else {
      throw new Error("Not a valid user");
    }
  } catch (e) {
    return [null, e];
  }
};
