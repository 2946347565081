import React from "react";
import { Link, useLocation } from "react-router-dom";

export const NavigationBar = () => {
  const location = useLocation();

  return (
    <div className="fixed bottom-0 w-full bg-white shadow-[0px_-2px_6px_#f3f3f3]">
      <div className=" max-w-md flex justify-between px-8 py-4 mx-auto">
        <Link exact="true" to="/">
          <div
            className={
              location.pathname === "/"
                ? "flex flex-col items-center active"
                : "flex flex-col items-center"
            }
          >
            <svg
              className="h-8 w-auto mb-2"
              viewBox="0 0 202 202"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#DAD3CC"
                opacity="0.4"
                d="M48.84 36.5733L145.053 4.46667C188.253 -9.93333 211.72 13.64 197.427 56.84L165.32 153.053C143.773 217.8 108.36 217.8 86.8133 153.053L77.32 124.467L48.7333 114.973C-15.9067 93.5333 -15.9067 58.2267 48.84 36.5733Z"
              />
            </svg>
            <div
              className={"text-sm font-semibold text-[#A59D93] is-active-text"}
            >
              Anasayfa
            </div>
          </div>
        </Link>
        <Link exact="true" to="/magaza">
          <div
            className={
              location.pathname === "/magaza"
                ? "flex flex-col items-center active"
                : "flex flex-col items-center"
            }
          >
            <svg
              className="h-8 w-auto mb-2"
              viewBox="0 0 256 256"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                d="M175.893 234.667H80.1066C42.6666 234.667 34.56 213.227 37.6533 186.987L47.2532 106.987C49.7066 86.2933 53.3332 69.3333 89.5999 69.3333H166.4C202.667 69.3333 206.293 86.2933 208.747 106.987L216.747 173.653L218.347 186.987C218.453 187.84 218.56 188.693 218.667 189.547C220.907 214.613 212.16 234.667 175.893 234.667Z"
                fill="#DAD3CC"
              />
              <path
                className="dark-path"
                d="M170.667 93.3333C166.293 93.3333 162.667 89.7066 162.667 85.3333V48C162.667 36.48 155.52 29.3333 144 29.3333H112C100.48 29.3333 93.3333 36.48 93.3333 48V85.3333C93.3333 89.7066 89.7067 93.3333 85.3333 93.3333C80.96 93.3333 77.3333 89.7066 77.3333 85.3333V48C77.3333 27.6266 91.6267 13.3333 112 13.3333H144C164.373 13.3333 178.667 27.6266 178.667 48V85.3333C178.667 89.7066 175.04 93.3333 170.667 93.3333Z"
                fill="#A38E82"
              />
              <path
                className="dark-path"
                d="M218.667 189.547C218.347 189.653 218.027 189.653 217.707 189.653H85.3333C80.96 189.653 77.3333 186.027 77.3333 181.653C77.3333 177.173 80.96 173.653 85.3333 173.653H216.747L218.347 186.987C218.453 187.84 218.56 188.693 218.667 189.547Z"
                fill="#A38E82"
              />
            </svg>
            <div className="text-sm font-semibold text-[#A59D93] is-active-text">
              Mağaza
            </div>
          </div>
        </Link>
        <Link exact="true" to="/fallarim">
          <div
            className={
              location.pathname === "/fallarim"
                ? "flex flex-col items-center active"
                : "flex flex-col items-center"
            }
          >
            <svg
              className="h-8 w-auto mb-2"
              viewBox="0 0 256 256"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                d="M231.04 111.36L220.587 155.947C211.627 194.453 193.92 210.027 160.64 206.827C155.307 206.4 149.547 205.44 143.36 203.947L125.44 199.68C80.96 189.12 67.2 167.147 77.6533 122.56L88.1067 77.8667C90.24 68.8 92.8 60.9067 96 54.4C108.48 28.5866 129.707 21.6533 165.333 30.08L183.147 34.24C227.84 44.6933 241.493 66.7733 231.04 111.36Z"
                fill="#DAD3CC"
              />
              <path
                className="dark-path"
                d="M160.64 206.827C154.027 211.307 145.707 215.04 135.573 218.347L118.72 223.893C76.3733 237.547 54.08 226.133 40.32 183.787L26.6667 141.653C13.0133 99.3067 24.32 76.9067 66.6667 63.2534L83.52 57.7067C87.8933 56.32 92.0533 55.1467 96 54.4C92.8 60.9067 90.24 68.8 88.1067 77.8667L77.6533 122.56C67.2 167.147 80.96 189.12 125.44 199.68L143.36 203.947C149.547 205.44 155.307 206.4 160.64 206.827Z"
                fill="#A38E82"
              />
              <path
                className="dark-path"
                d="M186.56 112.107C185.92 112.107 185.28 112 184.533 111.893L132.8 98.7733C128.533 97.7067 125.973 93.3333 127.04 89.0667C128.107 84.8 132.48 82.24 136.747 83.3067L188.48 96.4267C192.747 97.4933 195.307 101.867 194.24 106.133C193.387 109.653 190.08 112.107 186.56 112.107Z"
                fill="#A38E82"
              />
              <path
                className="dark-path"
                d="M155.307 148.16C154.667 148.16 154.027 148.053 153.28 147.947L122.24 140.053C117.973 138.987 115.413 134.613 116.48 130.347C117.547 126.08 121.92 123.52 126.187 124.587L157.227 132.48C161.493 133.547 164.053 137.92 162.987 142.187C162.133 145.813 158.933 148.16 155.307 148.16Z"
                fill="#A38E82"
              />
            </svg>
            <div className="text-sm font-semibold text-[#A59D93] is-active-text">
              Fallarım
            </div>
          </div>
        </Link>
        <Link exact="true" to="/profilim">
          <div
            className={
              location.pathname === "/profilim"
                ? "flex flex-col items-center active"
                : "flex flex-col items-center"
            }
          >
            <svg
              className="h-8 w-auto mb-2"
              viewBox="0 0 256 256"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                d="M128 234.773C186.91 234.773 234.667 187.017 234.667 128.107C234.667 69.1963 186.91 21.44 128 21.44C69.0896 21.44 21.3333 69.1963 21.3333 128.107C21.3333 187.017 69.0896 234.773 128 234.773Z"
                fill="#D6D3D1"
              />
              <path
                className="dark-path"
                d="M128 74.0267C105.92 74.0267 88 91.9467 88 114.027C88 135.68 104.96 153.28 127.467 153.92C127.787 153.92 128.213 153.92 128.427 153.92C128.64 153.92 128.96 153.92 129.173 153.92C129.28 153.92 129.387 153.92 129.387 153.92C150.933 153.173 167.893 135.68 168 114.027C168 91.9467 150.08 74.0267 128 74.0267Z"
                fill="#A38E82"
              />
              <path
                className="dark-path"
                d="M200.32 206.507C181.333 224 155.947 234.773 128 234.773C100.053 234.773 74.6667 224 55.68 206.507C58.24 196.8 65.1733 187.947 75.3067 181.12C104.427 161.707 151.787 161.707 180.693 181.12C190.933 187.947 197.76 196.8 200.32 206.507Z"
                fill="#A38E82"
              />
            </svg>
            <div className="text-sm font-semibold text-[#A59D93] is-active-text">
              Profilim
            </div>
          </div>
        </Link>
      </div>
      <style jsx="true">{`
        .active .is-active-text {
          color: #935431;
        }
        .active svg path {
          fill: #d1b3a5;
        }
        .active .dark-path {
          fill: #935431;
        }
      `}</style>
    </div>
  );
};

export default NavigationBar;
